import { useEffect } from 'react';
import { useQuery } from 'react-query';
import camelcaseKeys from 'camelcase-keys';
import { MESSAGE_TYPES } from 'common/constants';
import { getTBKAuth } from 'api/get-tbk-auth';

export const useTBKToken = () => {
  const { data: tbkUserInfo } = useQuery(
    [MESSAGE_TYPES.GET_TBK_AUTH_MESSAGE_TYPE],
    () => getTBKAuth(),
    {
      select: (data) => camelcaseKeys(data, { deep: true }),
    },
  );

  useEffect(() => {
    if (tbkUserInfo?.token) {
      localStorage.setItem('tbkUserInfo', JSON.stringify(tbkUserInfo?.token));
    }
  }, [tbkUserInfo]);

  return tbkUserInfo;
};
