import { useMediaQuery, queryHelpers, Box } from '@leagueplatform/genesis-core';
import landingPagePhoto from 'assets/landing-page-photo.jpg';

export const LandingPageBackgroundImageSection = () => {
  const isMobile = useMediaQuery(queryHelpers.between([0, 'tablet']));

  return (
    <Box
      css={{
        height: '100vh',
        '@mobile': {
          height: '45vh',
          minHeight: '45vh',
        },
      }}
    >
      <Box
        css={{
          backgroundImage: `url(${landingPagePhoto})`,
          width: '100%',
          height: '100%',
          backgroundPosition: isMobile ? '50% 15%' : '0 top',
          backgroundSize: 'cover',
          clipPath: isMobile
            ? 'ellipse(97% 95% at top)'
            : 'ellipse(100% 80% at left)',
        }}
      />
    </Box>
  );
};
