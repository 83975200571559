import { useState, useCallback } from 'react';

export const useModal = () => {
  const [isShowing, setIsShowing] = useState(false);

  const toggle = useCallback(() => {
    setIsShowing((current) => !current);
  }, []);

  return { isShowing, toggle };
};
