import type { OnboardingStage } from 'common/types';
import { useIntl } from '@leagueplatform/locales';
import { HeadingText } from '@leagueplatform/genesis-core';
import { MainPageContainer } from '@leagueplatform/web-common-components';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import { GET_USER_PROFILE_MESSAGE_TYPE } from '@leagueplatform/user-profile-api';
import { useQueryClient } from 'react-query';
import { ConsentContent } from 'components/onboarding/consent/consent-content.component';
import { ConsentFooter } from 'components/onboarding/consent/consent-footer.component';
import { FullScreenContainer } from 'components/full-screen-container/full-screen-container.component';
import privacyImage from 'assets/privacy.svg';
import { useAcceptGinaConsent } from 'hooks/use-accept-gina-consent.hook';
import { GinaAgreementContentPage } from 'pages/agreement-content/gina-agreement-content.page';
import { useFeatureFlags } from 'hooks/use-feature-flags.hook';
import { FEATURE_FLAGS } from 'common/firebase';
import { useSetAgreementStatus } from 'hooks/use-set-agreement-status.hook';
import { AGREEMENT_STATUS_TYPES } from 'common/constants';
import { ginaConsentContent } from 'components/onboarding/consent/data';

export const GinaConsent = ({
  setStatus,
}: {
  setStatus: (stage: OnboardingStage) => void;
}) => {
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'GINA_CONSENT_SUB_TITLE' }));
  const { loading, handleAcceptGinaConsent } = useAcceptGinaConsent(() =>
    setStatus('completed'),
  );
  const { mutate: mutateAgreementStatus, isLoading: mutationIsLoading } =
    useSetAgreementStatus();
  const { featureFlags } = useFeatureFlags([FEATURE_FLAGS.CONSENT_MANAGEMENT]);
  const queryClient = useQueryClient();

  if (featureFlags[FEATURE_FLAGS.CONSENT_MANAGEMENT]) {
    const handleOnAccept = () =>
      mutateAgreementStatus(
        {
          agreementType: AGREEMENT_STATUS_TYPES.GINA,
          isAccepted: true,
        },
        {
          onSuccess: () => {
            // refetch user profile data
            queryClient.resetQueries(GET_USER_PROFILE_MESSAGE_TYPE);

            setStatus('completed');
          },
          onError: () => {
            toastActions.add({
              type: TOAST_STATUS.ERROR,
              textId: formatMessage({ id: 'GINA_CONSENT_ERROR' }),
            });
          },
        },
      );

    return (
      <GinaAgreementContentPage
        fullScreen
        heading={
          <HeadingText level="2" size="xl" css={{ padding: '$half' }}>
            {formatMessage({ id: 'GINA_CONSENT_TITLE' })}
          </HeadingText>
        }
        footer={
          <ConsentFooter
            isLoading={mutationIsLoading}
            acceptText={formatMessage({ id: 'AGREE_AND_CONTINUE' })}
            backButton={{
              text: formatMessage({ id: 'BACK' }),
              onBack: () => setStatus('accountSetup'),
            }}
            onAccept={handleOnAccept}
          />
        }
      />
    );
  }

  // TODO remove when rel_as_consent_management feature flag is deprecated.
  return (
    <FullScreenContainer>
      <MainPageContainer width="100%" height="100%">
        <ConsentContent
          title={formatMessage({ id: 'GINA_CONSENT_TITLE' })}
          subTitle={formatMessage({ id: 'GINA_CONSENT_SUB_TITLE' })}
          content={ginaConsentContent}
          image={{
            src: privacyImage,
            alt: '',
            width: '115px',
          }}
        />
        <ConsentFooter
          isLoading={loading}
          acceptText={formatMessage({ id: 'AGREE_AND_CONTINUE' })}
          backButton={{
            text: formatMessage({ id: 'BACK' }),
            onBack: () => setStatus('accountSetup'),
          }}
          onAccept={handleAcceptGinaConsent}
        />
      </MainPageContainer>
    </FullScreenContainer>
  );
};
