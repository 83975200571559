import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  GDSStyleObject,
  ParagraphText,
  TextAction,
} from '@leagueplatform/genesis-core';
import { OpensInANewTabMessage } from '@leagueplatform/web-common-components';

interface Props {
  messageId: string;
  linkHref: string;
  linkTextId: string;
  id?: string;
  css?: GDSStyleObject;
}

export const CaptionWithLink: React.FC<Props> = ({
  messageId,
  linkHref,
  linkTextId,
  id,
  css,
}) => {
  const { formatMessage } = useIntl();
  return (
    <ParagraphText size="xs" id={id} css={{ ...css }}>
      {formatMessage(
        { id: messageId },
        {
          link: (
            <TextAction
              as="a"
              href={linkHref}
              target="_blank"
              rel="noopener noreferrer"
              css={{
                fontWeight: 700,
                fontSize: 'inherit',
                lineHeight: 'inherit',
                textDecoration: 'underline',
              }}
            >
              {formatMessage({
                id: linkTextId,
              })}
              <OpensInANewTabMessage />
            </TextAction>
          ),
        },
      )}
    </ParagraphText>
  );
};
