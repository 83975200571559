import {
  GDSPrimaryButtonTokens,
  GDSSecondaryButtonTokens,
  GDSTertiaryButtonTokens,
} from '@leagueplatform/genesis-core';

// Use primary colours for primary buttons.
const primary: GDSPrimaryButtonTokens = {
  background: {
    default: '#002677',
    hovered: '#5972A7',
    pressed: '#26478B',
    critical: {
      default: '#BA1B3B',
      hovered: '#A51834',
      pressed: '#711124',
    },
  },
  text: {
    default: '#FFFFFF',
    critical: {
      default: '#FFFFFF',
    },
  },
};

// Use secondary colours for secondary buttons.
const secondary: GDSSecondaryButtonTokens = {
  background: {
    default: 'transparent',
    hovered: '#5972A7',
    pressed: '#26478B',
  },
  text: {
    default: '#002677',
    hovered: '#FFFFFF',
  },
  border: {
    default: '#002677',
  },
};

// Use tertiary colours for tertiary buttons.
const tertiary: GDSTertiaryButtonTokens = {
  background: {
    default: 'transparent',
    hovered: '#5972A7',
    pressed: '#26478B',
  },
  text: {
    default: '#66686B',
    hovered: '#FFFFFF',
  },
  border: {
    default: '#949294',
  },
};

export const buttonTokens = {
  primary,
  secondary,
  tertiary,
};
