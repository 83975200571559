import { useContext, useEffect } from 'react';
import { BasePageContext } from 'components/base-page-provider/base-page-provider.component';

/**
 * Hide / show the app's navigation when the component is mounted
 * @param {boolean} showNavigation
 */
export const useToggleNavigation = (showNavigation: boolean) => {
  const { setShowNavigation } = useContext(BasePageContext);

  useEffect(() => {
    setShowNavigation(showNavigation);
  }, [setShowNavigation, showNavigation]);
};
