import { leagueFetch } from '@leagueplatform/league-fetch';
import { REST_ENDPOINTS } from 'common/constants';

export const getPartnerUserIdentifiers = async () => {
  const response = await leagueFetch(REST_ENDPOINTS.PARTNER_USER_IDENTIFIERS);

  if (!response?.ok) {
    throw new Error(
      `Failed to fetch ${
        REST_ENDPOINTS.PARTNER_USER_IDENTIFIERS
      } : ${await response.text()}`,
    );
  }

  const data = await response.json();

  return data.data?.attributes;
};
