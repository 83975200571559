import { Fragment } from 'react';
import { StackLayout } from '@leagueplatform/genesis-core';
import { useHistory } from '@leagueplatform/routing';
import {
  MainPageContainer,
  BackButtonArrow,
} from '@leagueplatform/web-common-components';
import { useGetAgreementContent } from 'hooks/use-get-agreement-content.hook';
import { ErrorTypes } from 'hooks/use-error-message.hook';
import { FullScreenContainer } from 'components/full-screen-container/full-screen-container.component';
import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';
import { FullPageError } from 'components/error/full-page-error.component';
import { AgreementContent } from 'components/agreement-content/agreement-content.component';
import { AGREEMENT_CONTENT_TYPES } from 'common/constants';

export const GinaAgreementContentPage = ({
  fullScreen,
  includeBackButton,
  heading,
  footer,
}: {
  fullScreen?: boolean;
  includeBackButton?: boolean;
  heading?: JSX.Element;
  footer?: JSX.Element;
}) => {
  const history = useHistory();
  const {
    data: contentData,
    isLoading: agreementContentisLoading,
    isError,
  } = useGetAgreementContent(AGREEMENT_CONTENT_TYPES.GINA);

  if (agreementContentisLoading) return <FullPageLoadingSpinner />;

  if (isError)
    return <FullPageError errorType={ErrorTypes.API_CONNECTION_ERROR} />;

  const OptionalFullScreenContainer = fullScreen
    ? FullScreenContainer
    : Fragment;

  return (
    <OptionalFullScreenContainer>
      <MainPageContainer>
        {includeBackButton && (
          <BackButtonArrow
            display="block"
            fontSize="heading3"
            color="onSurface.text.primary"
            justifyContent="center"
            width="fit-content"
            onClick={() => history.goBack()}
          />
        )}
        <StackLayout
          css={{
            paddingTop: fullScreen ? '$one' : '0',
            paddingBottom: fullScreen ? '$six' : '0',
          }}
          spacing="$two"
          horizontalAlignment="center"
        >
          {heading}
          <AgreementContent
            htmlString={contentData?.content}
            title={contentData?.title}
            scrollMarginTop={fullScreen ? 0 : '$six'}
          />
        </StackLayout>
        {footer}
      </MainPageContainer>
    </OptionalFullScreenContainer>
  );
};
