import { leagueFetch } from '@leagueplatform/league-fetch';
import { REST_ENDPOINTS, AGREEMENT_STATUS_TYPES } from 'common/constants';

export type GetAgreementStatusParams = {
  acceptanceStatus?: boolean;
  types?: (typeof AGREEMENT_STATUS_TYPES)[keyof typeof AGREEMENT_STATUS_TYPES][];
};

export const getAgreementStatus = async (params?: GetAgreementStatusParams) => {
  const searchParams = [];

  if (params?.acceptanceStatus !== undefined) {
    searchParams.push(['acceptanceStatus', params.acceptanceStatus.toString()]);
  }

  if (params?.types?.length) {
    params.types.forEach((type) => {
      searchParams.push(['types', type]);
    });
  }

  const response = await leagueFetch(
    `${REST_ENDPOINTS.USER_AGREEMENTS_STATUS}?${new URLSearchParams(
      searchParams,
    ).toString()}`,
  );

  if (response?.ok) {
    const data = await response.json();
    return data?.data;
  }

  throw new Error();
};
