import { leagueFetch } from '@leagueplatform/league-fetch';
import { REST_ENDPOINTS } from 'common/constants';

export const requestCallback = async (phoneNumber: string) => {
  const response = await leagueFetch(REST_ENDPOINTS.CALLBACKS, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'optum-post-callback',
        attributes: {
          telephoneNumber: phoneNumber,
        },
      },
    }),
  });

  if (response.ok) {
    return true;
  }

  throw new Error();
};
