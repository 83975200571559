import { useEffect } from 'react';
import { captureAppError } from '@leagueplatform/observability';
import { useQueryParams } from '@leagueplatform/routing';
import {
  PRODUCT_AREA,
  SUB_PRODUCT_AREA,
  sendAnalyticsPageView,
} from '@leagueplatform/analytics';
import { useGetPartnerAccessToken } from 'hooks/use-get-partner-access-token.hook';
import { useToggleNavigation } from 'hooks/use-toggle-navigation/use-toggle-navigation.hook';
import { useGetPartnerUserIdentifiers } from 'hooks/use-get-partner-user-identifiers.hook';
import {
  SENTRY_CONSTANTS,
  EPMP_DISPLAY_QUERY_PARAM,
  SCREEN_NAMES,
} from 'common/constants';
import { ENV } from 'common/environment-variables';
import type { PartnerUserIdentifiers } from 'common/types';

const viewPreferencesAnalyticsEvent = () =>
  sendAnalyticsPageView({
    product_area: PRODUCT_AREA.SETTINGS,
    sub_product_area: SUB_PRODUCT_AREA.COMMUNICATION_PREFERENCES,
    screen_name: SCREEN_NAMES.EPMP_PREFERENCES,
  });

const viewContactAnalyticsEvent = () =>
  sendAnalyticsPageView({
    product_area: PRODUCT_AREA.SETTINGS,
    sub_product_area: SUB_PRODUCT_AREA.PERSONAL_INFO,
    screen_name: SCREEN_NAMES.EPMP_CONTACT_INFORMATION,
  });

const defaultFontStyles = {
  fontFamily: 'UHC Sans',
  fontStyle: 'normal',
};
const defaultGlobalLoaderConfig = {
  theme: {
    fonts: {
      light: {
        ...defaultFontStyles,
        fontWeight: '300',
      },
      normal: {
        ...defaultFontStyles,
        fontWeight: '400',
      },
      bold: {
        ...defaultFontStyles,
        fontWeight: '700',
      },
    },
  },
};

export const EPMPPage = () => {
  useToggleNavigation(false);
  const displayQueryParam = useQueryParams().get('display');
  const { data: token } = useGetPartnerAccessToken();
  const { data: userIdentifiers } = useGetPartnerUserIdentifiers();

  useEffect(() => {
    if (token && userIdentifiers) {
      const {
        firstName,
        lastName,
        dateOfBirth,
        subscriberId,
        policyNumber,
        sourceSystemCode,
      }: PartnerUserIdentifiers = userIdentifiers;

      const initializeGlobalLoader = (configUrl: string) =>
        window?.globalLoader?.initialize({
          ...defaultGlobalLoaderConfig,
          globalLoaderConfigUrl: configUrl,
          token,
          userData: {
            lastName,
            firstName,
            policyNumber,
            dob: dateOfBirth, // format YYYY-MM-DD
            idType: sourceSystemCode === 'OP' ? 'ALTOP' : 'SCR',
            idValue: subscriberId,
          },
        });

      try {
        switch (displayQueryParam?.toLowerCase()) {
          case EPMP_DISPLAY_QUERY_PARAM.PREFERENCES:
            initializeGlobalLoader(ENV.VITE_EPMP_PREFERENCE_CONFIG_URL);
            break;
          case EPMP_DISPLAY_QUERY_PARAM.CONTACT:
            initializeGlobalLoader(ENV.VITE_EPMP_CONTACT_CONFIG_URL);
            break;
          default:
            initializeGlobalLoader(ENV.VITE_EPMP_PREFERENCE_CONFIG_URL);
            initializeGlobalLoader(ENV.VITE_EPMP_CONTACT_CONFIG_URL);
        }
      } catch (e) {
        if (e instanceof Error) {
          captureAppError(e, {
            ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
            errorName: 'Error initializing global loader (EPMP)',
            tags: {
              display: displayQueryParam,
            },
            context: {
              Context: {
                initalize:
                  displayQueryParam ||
                  `${EPMP_DISPLAY_QUERY_PARAM.PREFERENCES} & ${EPMP_DISPLAY_QUERY_PARAM.CONTACT}`,
              },
            },
          });
        }

        throw e;
      } finally {
        // Send analytics event
        switch (displayQueryParam?.toLowerCase()) {
          case EPMP_DISPLAY_QUERY_PARAM.PREFERENCES:
            viewPreferencesAnalyticsEvent();
            break;
          case EPMP_DISPLAY_QUERY_PARAM.CONTACT:
            viewContactAnalyticsEvent();
            break;
          default:
            viewPreferencesAnalyticsEvent();
            viewContactAnalyticsEvent();
        }
      }
    }
  }, [token, userIdentifiers, displayQueryParam]);

  return (
    <>
      <epmp-preferences />
      <epmp-contact />
    </>
  );
};
