import { useEffect, useRef, useState } from 'react';

export const useElementHeight = () => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [elementHeight, setElementHeight] = useState(0);

  useEffect(() => {
    if (!elementRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setElementHeight(elementRef?.current?.offsetHeight ?? 0);
    });
    resizeObserver.observe(elementRef.current);
    // eslint-disable-next-line consistent-return
    return () => resizeObserver.disconnect(); // clean up
  }, [elementHeight]);

  return {
    elementRef,
    elementHeight,
  };
};
