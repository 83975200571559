import { useEffect } from 'react';
import { useLocation } from '@leagueplatform/routing';
import {
  type GDSSpacingValues,
  StackLayout,
  Box,
  HeadingText,
} from '@leagueplatform/genesis-core';
import { HtmlToReact } from '@leagueplatform/web-common';
import IMAGE from 'assets/privacy.svg';

export const AgreementContent = ({
  imageSrc = IMAGE,
  htmlString,
  title,
  scrollMarginTop = 0,
}: {
  imageSrc?: string;
  htmlString: string | undefined;
  title?: string;
  scrollMarginTop?: number | GDSSpacingValues;
}) => {
  const location = useLocation();

  useEffect(() => {
    // When content is rendered, scroll to the url hash if necessary
    if (location?.hash) {
      const targetElement = document.getElementById(location.hash.substring(1));
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'auto' });
      }
    }
  }, [location.hash]);

  return (
    <StackLayout
      orientation="vertical"
      horizontalAlignment="stretch"
      spacing="$two"
      css={{
        width: '100%',
        maxWidth: 766,
        margin: 'auto',
      }}
    >
      <img src={imageSrc} alt="" style={{ width: 128, alignSelf: 'center' }} />
      <StackLayout spacing="$one" horizontalAlignment="stretch">
        {title && (
          <HeadingText
            level="1"
            size="xl"
            css={{
              marginBottom: 0,
              alignSelf: 'center',
            }}
          >
            {title}
          </HeadingText>
        )}
        <Box
          css={{
            '.GDS-heading-text, table': {
              marginBlockEnd: '$half',
            },
            '[id]': {
              scrollMarginTop,
            },
            table: {
              width: 'fit-content',
              '@mobile': {
                'a[href^="mailto"]': {
                  wordBreak: 'break-word',
                },
              },
            },
          }}
        >
          <HtmlToReact
            htmlString={htmlString}
            options={{
              // Since the title is set outside of this HTMLToReact component, prevent multiple h1 elements from appearning on the page.
              h1: {
                component: HeadingText as React.FunctionComponent,
                props: { level: '2', size: 'lg' },
              },
              h2: {
                component: HeadingText as React.FunctionComponent,
                props: { level: '2', size: 'lg' },
              },
            }}
          />
        </Box>
      </StackLayout>
    </StackLayout>
  );
};
