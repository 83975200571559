import { OnboardingRoutesWithProvider } from '@leagueplatform/onboarding';
import { FullScreenContainer } from 'components/full-screen-container/full-screen-container.component';
import { useConfigProperty } from '@leagueplatform/core';

export const FeatureHighlights = ({
  onComplete,
}: {
  onComplete: () => void;
}) => {
  const tenantId: string | undefined = useConfigProperty('core.tenantId');

  return (
    <FullScreenContainer>
      <OnboardingRoutesWithProvider
        contentfulId={import.meta.env.VITE_CONTENTFUL_ID}
        tenantId={tenantId!}
        onOnboardingComplete={onComplete}
      />
    </FullScreenContainer>
  );
};
