import { ObservabilityErrorBoundary } from '@leagueplatform/observability';
import { ErrorTypes } from 'hooks/use-error-message.hook';
import { FullPageError } from './full-page-error.component';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

export const ErrorFallback = () => (
  <FullPageError errorType={ErrorTypes.API_ERROR} />
);

export function ErrorBoundaryWrapper({ children }: ErrorBoundaryProps) {
  return (
    <ObservabilityErrorBoundary fallback={<ErrorFallback />}>
      {children}
    </ObservabilityErrorBoundary>
  );
}
