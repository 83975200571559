import { BasePage } from 'components/base-page';
import type { ReactNode, Dispatch, SetStateAction } from 'react';
import { createContext, useState, useMemo } from 'react';

const showNavigationDefault = true;

export const BasePageContext = createContext<{
  setShowNavigation: Dispatch<SetStateAction<boolean>>;
}>({
  setShowNavigation: () => showNavigationDefault,
});

export const BasePageProvider = ({ children }: { children: ReactNode }) => {
  const [showNavigation, setShowNavigation] = useState<boolean>(
    showNavigationDefault,
  );

  const basePageContextValue = useMemo(
    () => ({
      setShowNavigation,
    }),
    [setShowNavigation],
  );

  return (
    <BasePageContext.Provider value={basePageContextValue}>
      <BasePage showNavigation={showNavigation}>{children}</BasePage>
    </BasePageContext.Provider>
  );
};
