import { useState } from 'react';
import { ReacceptGINAAgreementPrompt } from 'components/reaccept-gina-agreement/reaccept-gina-agreement-prompt.component';
import { ReacceptGINAAgreementContent } from 'components/reaccept-gina-agreement/reaccept-gina-agreement-content.component';
import { ReacceptGINAAgreementConfirmation } from 'components/reaccept-gina-agreement/reaccept-gina-agreement-confirmation.component';
import { REACCEPT_GINA_FLOW_STATUS } from 'common/constants';
import type { ReacceptGINAFlowStatus } from 'common/types';

export const ReacceptGINAAgreement = ({
  initialView = REACCEPT_GINA_FLOW_STATUS.PROMPT,
  endReacceptanceFlow = () => {},
  handleGoBack,
}: {
  initialView?: ReacceptGINAFlowStatus;
  endReacceptanceFlow?: () => void;
  handleGoBack?: () => void;
}) => {
  const [status, setStatus] = useState<ReacceptGINAFlowStatus>(initialView);

  if (status === REACCEPT_GINA_FLOW_STATUS.CONTENT) {
    return (
      <ReacceptGINAAgreementContent
        onAcceptSuccess={() =>
          setStatus(REACCEPT_GINA_FLOW_STATUS.CONFIRMATION)
        }
        handleGoBack={handleGoBack}
      />
    );
  }

  if (status === REACCEPT_GINA_FLOW_STATUS.CONFIRMATION) {
    return <ReacceptGINAAgreementConfirmation onClose={endReacceptanceFlow} />;
  }

  return (
    <ReacceptGINAAgreementPrompt
      handleButtonOnClick={() => setStatus(REACCEPT_GINA_FLOW_STATUS.CONTENT)}
    />
  );
};
