import { GDSStatusTokens } from '@leagueplatform/genesis-core';
// Critical colours are for destructive interactive elements, errors, and critical events that require immediate action.
const critical: GDSStatusTokens = {
  background: {
    subdued: '#F9DEE3',
    secondary: '#FFFFFF',
  },
  border: {
    default: '#BA1B3B',
  },
  icon: '#BA1B3B',
};

// Warning colours let the user know they need to take action.
const warning: GDSStatusTokens = {
  background: {
    subdued: '#FFE4BB',
    secondary: '#FFFFFF',
  },
  border: {
    default: '#FD9833',
  },
  icon: '#FD9833',
};

// Success colours indicate something positive, like the success of an action.
const success: GDSStatusTokens = {
  background: {
    subdued: '#E3F6F1',
    secondary: '#FFFFFF',
  },
  border: {
    default: '#00A876',
  },
  icon: '#00A876',
};

//  Highlight colours indicate important elements that don’t require immediate action.
const highlight: GDSStatusTokens = {
  background: {
    subdued: '#F0F8FB',
    secondary: '#FFFFFF',
  },
  border: {
    default: '#2E5CDF',
  },
  icon: '#2E5CDF',
};

export const statusTokens = {
  critical,
  warning,
  success,
  highlight,
};
