import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import camelcaseKeys from 'camelcase-keys';
import { MESSAGE_TYPES } from 'common/constants';

interface AppInitDetailsReturn {
  eligible: boolean;
  [key: string]: unknown;
}

export const getAppInitDetails = (): Promise<AppInitDetailsReturn> =>
  SocketAsFetch.fetch({
    message_type: MESSAGE_TYPES.GET_APP_INIT_DETAILS,
    info: {
      platform: 'web',
    },
  }).then((data) => camelcaseKeys(data, { deep: true }));
