import { UtilityText } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { PRIVACY_POLICY_ROUTE, TERMS_OF_USE_ROUTE } from 'common/constants';

export const TermAndConditionsText = () => {
  const { formatMessage } = useIntl();

  return (
    <UtilityText>
      {formatMessage({ id: 'I_HAVE_READ' })}{' '}
      <a href={TERMS_OF_USE_ROUTE} target="_blank" rel="noreferrer">
        {formatMessage({ id: 'TERMS_OF_SERVICE' })}
      </a>{' '}
      {formatMessage({ id: 'AND' })}{' '}
      <a href={PRIVACY_POLICY_ROUTE} target="_blank" rel="noreferrer">
        {formatMessage({ id: 'PRIVACY_POLICY' })}
      </a>
      .
    </UtilityText>
  );
};
