import { useGetUserProfile } from '@leagueplatform/web-common';

export const usePreferredFirstName = () => {
  const { data, isLoading, error } = useGetUserProfile();
  const preferredFirstName =
    data?.userProfile?.preferredFirstName ??
    data?.userProfile?.firstName ??
    data?.email ??
    '';

  return { data: { preferredFirstName }, isLoading, error };
};
