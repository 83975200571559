import type { GinaContent } from 'common/types';

export const ginaConsentContent: GinaContent[] = [
  {
    id: '1',
    type: 'paragraph',
    content:
      'Before you utilize chat and/or document upload functionality available in this digital solution, we would like to provide you with some information about the health information you may disclose to us in the course of using chat or document upload, how and why we use it, and how we protect it.  You are not required to utilize the chat or document upload functionality.  Participation is completely voluntary.   However, if you choose to participate, you may receive incentives and/or information on services to help you manage your health.  If you choose not to participate, you may not receive program incentives, if any, offered by your health plan for participation in such  programs.',
  },
  {
    id: '2',
    type: 'heading',
    content: 'What information do we obtain?',
  },
  {
    id: '3',
    type: 'listItems',
    content: [
      'In the course of using chat and document upload functionality, you may elect to disclose certain health information to us.  During the chat, we may also discuss results of your biometric screenings or health risk assessments, or provide educational guidance related to your participation in our wellness coaching or condition management programs.',
    ],
  },
  {
    id: '4',
    type: 'heading',
    content: 'How do we use your information?',
  },
  {
    id: '5',
    type: 'listItems',
    content: [
      'Information provided to us during a chat session or through document upload may be used to identify services that may be relevant to you, or to assist you in navigating your health care needs, such as identifying a health care provider to assist you with a condition.',
    ],
  },
  {
    id: '6',
    type: 'heading',
    content: 'How do we share and protect your information?',
  },
  {
    id: '7',
    type: 'listItems',
    content: [
      'The health information you give us as part of a health and wellness program is generally not shared with your spouse’s/parent’s employer, although we may share certain information (e.g., the amount of incentives earned), as necessary where their employer or their designated third party vendor is administering incentive awards. We will share information with our wellness coaches, nurses, and doctors, whom are involved in administering your wellness and condition management programs and health plan. We only share information with our vendors and subcontractors in accordance with applicable laws, including HIPAA, as necessary to administer your wellness and condition management programs or health plan, and anyone who receives information from us for purposes of providing you services is required to abide by the same confidentiality requirements.',
      'We take reasonable precautions to protect data and to avoid data breaches, including maintaining physical, technical, and administrative safeguards. Such safeguards may include, for example, firewalls, encryption, identity management, and intrusion prevention/detection to prevent improper access, use, and disclosure of your data in our networks. In the event of a data breach involving information you provide in connection with the wellness program, we will notify you within the time periods required by applicable laws, including HIPAA.',
    ],
  },
  {
    id: '8',
    type: 'heading',
    content: 'For a written authorization:',
  },
  {
    id: '9',
    type: 'paragraph',
    content: 'By clicking “Agree and Continue”— below:',
  },
  {
    id: '10',
    type: 'listItems',
    content: [
      'I affirm that I have read and understood this authorization;',
      'I am intending to electronically sign this authorization;',
      'I understand that participation in any programs noted above is completely voluntary; and',
      'I agree that if I choose to participate in any programs noted above, I, on behalf of myself and any minor child participating in such programs, authorize the collection and use of my/their data as described in this authorization',
    ],
  },
];
