import { useEffect } from 'react';
import { useHistory } from '@leagueplatform/routing';
import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';
import { AuthErrorModal } from 'components/modals/auth-error-modal.component';
import { useAppAuthorization } from 'hooks/use-app-authorization.hook';
import { useModal } from 'hooks/use-modal.hook';

export const AuthSessionContainer = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const history = useHistory();
  const { isAuthenticated, error, isEligible, isLoading } = useAppAuthorization(
    {
      checkUserEligibility: true,
      useAuthOptions: {
        redirectToLogInWhenSignedOut: true,
      },
    },
  );
  const { isShowing: showEligibilityModal, toggle: toggleEligibilityModal } =
    useModal();
  const errorDescription = error?.description;

  useEffect(() => {
    if (errorDescription) {
      toggleEligibilityModal();
    }
  }, [errorDescription, toggleEligibilityModal]);

  if (isLoading) {
    return <FullPageLoadingSpinner />;
  }
  if (errorDescription && !isEligible) {
    return (
      <AuthErrorModal
        showModal={showEligibilityModal}
        toggleModal={toggleEligibilityModal}
        error={errorDescription}
      />
    );
  }
  if (error || !isAuthenticated) {
    history.push('/');
    return null;
  }

  return children;
};
