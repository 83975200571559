import {
  GDSOnSurfaceTokens,
  GDSSurfaceTokens,
} from '@leagueplatform/genesis-core';

// Surface colours affect surfaces of components, such as pages, cards, sheets, and popovers.
const surface: GDSSurfaceTokens = {
  background: {
    primary: '#FFFFFF',
    secondary: '#F5F5F5',
    tertiary: '#D9F6FA',
  },
  card: {
    primary: '#F5F5F5',
    secondary: '#FFFFFF',
    disabled: '#D7D7D7',
  },
};

// On-surface colours are applied to elements that appear on neutral surfaces, typically text, borders, and secondary icons.
const onSurface: GDSOnSurfaceTokens = {
  text: {
    primary: '#222222',
    subdued: '#5A5A5A',
    reversed: '#FFFFFF',
    critical: '#BA1B3B',
    success: '#627D32',
    warning: '#222222',
  },
  border: {
    default: '#959595',
    subdued: '#E0E0E0',
  },
  icon: {
    primary: '#636363',
  },
};

export const surfaceTokens = {
  surface,
  onSurface,
};
