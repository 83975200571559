import { leagueFetch } from '@leagueplatform/league-fetch';
import type { AgreementStatus } from 'common/types';
import { REST_ENDPOINTS } from 'common/constants';

export const setAgreementStatus = async (attributes: AgreementStatus) => {
  const response = await leagueFetch(REST_ENDPOINTS.USER_AGREEMENTS_STATUS, {
    method: 'PATCH',
    body: JSON.stringify({
      data: {
        id: attributes.agreementType,
        type: 'new-optum-agreement',
        attributes,
      },
    }),
  });

  if (response.ok) {
    return true;
  }

  throw new Error(`PATCH user-agreements-status error: ${response.status}`);
};
