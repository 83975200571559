import { useQuery } from 'react-query';
import { getCallbackStatus } from 'api/get-callback-status';
import { REST_ENDPOINTS, CALLBACK_STATUS } from 'common/constants';

export const useGetCallbackStatus = () => {
  const { data, isError } = useQuery(
    [REST_ENDPOINTS.CALLBACKS],
    getCallbackStatus,
    {
      cacheTime: 0,
    },
  );

  return {
    isError,
    isQueued: data === CALLBACK_STATUS.QUEUED,
    isCompleted: data === CALLBACK_STATUS.COMPLETED,
  };
};
