import { captureError } from '@leagueplatform/observability';
import { SENTRY_CONSTANTS, SSO_TYPES } from 'common/constants';
import { getSSOToken } from 'api/get-sso-token';

// handles links that require the PingFederate Attribute drop-off process to enable SSO.
export const handleDropoffSSO = async (targetUrl: URL) => {
  const ssoType = targetUrl?.searchParams.get('tokenType');

  try {
    const token = await getSSOToken(ssoType);

    // use the appropriate SSO Endpoint based on the SSO type
    let ssoEndpoint;

    switch (ssoType?.toLowerCase()) {
      case SSO_TYPES.RX:
        ssoEndpoint = import.meta.env.VITE_SSO_RX_DROPOFF_URL;
        break;
      case SSO_TYPES.CPV:
        ssoEndpoint = import.meta.env.VITE_SSO_CPVIDEO_DROPOFF_URL;
        break;
      case SSO_TYPES.BANK:
        ssoEndpoint = import.meta.env.VITE_SSO_OPTUM_BANK_URL;
        break;
      default:
        ssoEndpoint = import.meta.env.VITE_SSO_UHC_DROPOFF_URL;
        break;
    }

    // assemble SSO link
    const url = new URL(ssoEndpoint);
    url.searchParams.append('TARGET', targetUrl.href);
    url.searchParams.append('REF', token);

    // redirect user to sso link
    window.open(url.href, '_blank', 'noreferrer');
  } catch (e) {
    captureError(new Error('Failed to assemble SSO link'), {
      errorName: 'SSO External Link Error - Failed to assemble SSO link',
      context: {
        Context: {
          ssoUrl: targetUrl.href,
          message: e,
        },
      },
      ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
      tags: {
        sso: targetUrl.href,
      },
    });
  }
};
