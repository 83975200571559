import { leagueFetch } from '@leagueplatform/league-fetch';
import { REST_ENDPOINTS } from 'common/constants';

export const getPartnerUserCommunicationInfo = async () => {
  const response = await leagueFetch(
    REST_ENDPOINTS.PARTNER_USER_COMMUNICATION_INFO,
  );

  if (response.ok) {
    const data = await response.json();
    return data;
  }

  throw new Error(
    `Failed to fetch ${
      REST_ENDPOINTS.PARTNER_USER_COMMUNICATION_INFO
    } : ${await response.text()}`,
  );
};
