import { useCallback } from 'react';
import { useHistory } from '@leagueplatform/routing';
import { ReacceptGINAAgreement } from 'components/reaccept-gina-agreement/reaccept-gina-agreement.component';
import { REACCEPT_GINA_FLOW_STATUS } from 'common/constants';

export const SignHealthAuthorizationPage = () => {
  const { push, goBack } = useHistory();

  const returnToHomePage = useCallback(() => {
    push('/home');
  }, [push]);

  return (
    <ReacceptGINAAgreement
      initialView={REACCEPT_GINA_FLOW_STATUS.CONTENT}
      endReacceptanceFlow={returnToHomePage}
      handleGoBack={goBack}
    />
  );
};
