import {
  usePageViewAnalytics,
  EVENT_NAME,
  PRODUCT_AREA,
  SUB_PRODUCT_AREA,
  SCREEN_NAMES,
} from '@leagueplatform/analytics';
import { useIntl } from '@leagueplatform/locales';
import {
  StackLayout,
  HeadingText,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import logo from 'assets/care-advisor-vertical-logo.svg';

export const MaintenancePage = ({ endDate }: { endDate?: string }) => {
  const { formatMessage } = useIntl();
  usePageViewAnalytics(
    {
      product_area: PRODUCT_AREA.LOGIN,
      sub_product_area: SUB_PRODUCT_AREA.MAINTENANCE,
      screen_name: SCREEN_NAMES.MAINTENANCE_PAGE,
    },
    [],
    EVENT_NAME.SCREEN_LOADED,
  );

  return (
    <StackLayout
      css={{ padding: '$three', height: '100vh', textAlign: 'center' }}
      verticalAlignment="center"
      horizontalAlignment="center"
    >
      <img alt={formatMessage({ id: 'CARE_ADVISOR_CONNECT' })} src={logo} />
      <HeadingText
        level="1"
        size="md"
        css={{ marginBottom: '$half', marginTop: '$twoAndHalf' }}
      >
        {endDate
          ? formatMessage(
              { id: 'MAINTENANCE_PAGE_HEADING_WITH_DATE' },
              { endDate },
            )
          : formatMessage({ id: 'MAINTENANCE_PAGE_HEADING_NO_DATE' })}
      </HeadingText>

      <ParagraphText css={{ maxWidth: '475px' }}>
        {formatMessage({ id: 'MAINTENANCE_PAGE_BODY' })}
      </ParagraphText>
    </StackLayout>
  );
};
