import { useMutation, useQueryClient } from 'react-query';
import { captureAppMessage } from '@leagueplatform/observability';
import type { AgreementStatus } from 'common/types';
import { SENTRY_SEVERITY_LEVELS } from 'common/types';
import { setAgreementStatus } from 'api/set-agreement-status';
import { REST_ENDPOINTS, SENTRY_CONSTANTS } from 'common/constants';

export const useSetAgreementStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (attributes: AgreementStatus) => setAgreementStatus(attributes),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(REST_ENDPOINTS.USER_AGREEMENTS_STATUS),
      onError: (e, { agreementType, isAccepted }) => {
        // Capture in Sentry
        captureAppMessage(
          `Failed to update ${agreementType} agreement status`,
          {
            ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
            severityLevel: SENTRY_SEVERITY_LEVELS.ERROR,
            errorName:
              (e as Error)?.message || 'error updating user agreement status',
            tags: {
              agreementType,
              isAccepted,
            },
            context: {
              Context: {
                errorInfo: e ?? SENTRY_CONSTANTS.NO_INFO_AVAILABLE,
                agreementType,
                isAccepted,
              },
            },
          },
        );
      },
    },
  );
};
