import { leagueFetch } from '@leagueplatform/league-fetch';
import { REST_ENDPOINTS } from 'common/constants';

export const getSSOToken = async (ssoType: string | null) => {
  const response = await leagueFetch(
    `${REST_ENDPOINTS.SSO_TOKEN}${ssoType ? `?tokenType=${ssoType}` : ''}`,
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch SSO token: ${await response.text()}`);
  }

  const data = await response.json();
  const token = data.data?.attributes?.token;

  if (!token) {
    throw new Error('No token found in fetch SSO token response.');
  }

  return token;
};
