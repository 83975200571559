import { useState } from 'react';
import { useHistory } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import { acceptGinaConsent } from '../api/accept-gina-consent';

export const useAcceptGinaConsent = (successCallback: () => void) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { formatMessage } = useIntl();

  const handleGinaConsentError = () => {
    setLoading(false);
    toastActions.add({
      type: TOAST_STATUS.ERROR,
      textId: formatMessage({ id: 'GINA_CONSENT_ERROR' }),
    });
  };
  const handleAcceptGinaConsent = async () => {
    try {
      setLoading(true);
      const consentResponse = await acceptGinaConsent();
      if (consentResponse?.date_accepted) {
        history.push('/home');
        successCallback();
      } else {
        handleGinaConsentError();
      }
    } catch (error) {
      handleGinaConsentError();
    }
  };

  return {
    loading,
    handleAcceptGinaConsent,
  };
};
