import {
  HeadingText,
  Button,
  ParagraphText,
  Modal,
  ImageMessage,
} from '@leagueplatform/genesis-core';
import exitWarning from 'assets/exit-warning.svg';
import { BaseModalContainer } from './base-modal.component';

export interface Props {
  headingText: string;
  description: string;
  submitButtonText: string;
}

export const NotificationModal = ({
  headingText,
  description,
  submitButtonText,
}: Props) => (
  <BaseModalContainer
    maxWidth={381}
    footer={
      <Modal.Close>
        <Button
          priority="primary"
          width="fillContainer"
          css={{
            marginBlockStart: '$one',
          }}
        >
          {submitButtonText}
        </Button>
      </Modal.Close>
    }
  >
    <ImageMessage
      image={exitWarning}
      imageWidth={146}
      css={{ flexGrow: 1, padding: '$none $one', textAlign: 'center' }}
    >
      <Modal.Title>
        <HeadingText level="2" size="lg">
          {headingText}
        </HeadingText>
      </Modal.Title>
      <ParagraphText css={{ marginBlock: '$one' }}>{description}</ParagraphText>
    </ImageMessage>
  </BaseModalContainer>
);
