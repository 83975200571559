// expects a phone number with 10 digits
// optional brackets can be placed around the first 3 digits of the number
export const isPhoneNumber = (number: string) =>
  /^\s*?[(]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})\s*$/g.test(number);

// number groups can be 0-3 digits, but the special char separators can only appear once the max number of digits is reached
// Do a look behind to make sure the correct number of digits is present
export const isPartialPhoneNumber = (number: string) =>
  /^\(?((\d{3}(-|\s|\))?)|(\d{0,3}))(\s|-)?((\d{3}(-|\s)?)|(\d{0,3}))(\d{0,4})$/g.test(
    number,
  );

// strip phone number of any non-numerical characters
export const stripPhoneNumber = (number: string) => {
  const isDigit = /^\d$/;
  return [...number].filter((character) => isDigit.test(character)).join('');
};

const acceptedSpecialChars = ['(', ')', ' ', '-'];

// format phone number to (xxx) xxx-xxxx
export const formatPhoneNumber = (number: string) => {
  // if special characters () or - ' ' are typed
  // check if the number being typed matches the format (xxx) xxx-xxxx
  // if it does, just return it

  const lastChar = number.substring(number.length - 1);

  if (acceptedSpecialChars.includes(lastChar) && isPartialPhoneNumber(number))
    return number;

  // otherwise, let's format it correctly
  const numberArray = [...stripPhoneNumber(number)];

  // (xx
  if (numberArray.length > 0 && numberArray.length <= 3)
    return `(${numberArray.join('')}`;

  // (xxx) xxx-xxxx
  if (numberArray.length > 6)
    return `(${numberArray.slice(0, 3).join('')}) ${numberArray
      .slice(3, 6)
      .join('')}-${numberArray.slice(6, 10).join('')}`;

  // (xxx) xxx
  if (numberArray.length > 3)
    return `(${numberArray.slice(0, 3).join('')}) ${numberArray
      .slice(3)
      .join('')}`;

  return number;
};
