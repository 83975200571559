import { FullScreenContainer } from 'components/full-screen-container/full-screen-container.component';
import {
  Box,
  Button,
  HeadingText,
  ParagraphText,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import medicalIcon from 'assets/medical.svg';

export const ReacceptGINAAgreementPrompt = ({
  handleButtonOnClick,
}: {
  handleButtonOnClick: () => void;
}) => {
  const { formatMessage } = useIntl();

  return (
    <FullScreenContainer>
      <Box
        css={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <StackLayout
          orientation="vertical"
          horizontalAlignment="center"
          css={{
            textAlign: 'center',
            maxWidth: '327px',
          }}
          spacing="$three"
        >
          <img
            src={medicalIcon}
            alt=""
            style={{ width: 138, height: 'auto', position: 'relative' }}
          />
          <Box>
            <HeadingText
              size="xl"
              level="1"
              css={{ marginBottom: '$oneAndHalf' }}
            >
              {formatMessage({ id: 'EXPIRED_HEALTH_INFO_AUTH' })}
            </HeadingText>
            <ParagraphText emphasis="subtle" size="sm">
              {formatMessage({ id: 'GINA_REACCEPT_SIGN' })}
            </ParagraphText>
          </Box>

          <Button onClick={handleButtonOnClick}>
            {formatMessage({ id: 'CONTINUE' })}
          </Button>
        </StackLayout>
      </Box>
    </FullScreenContainer>
  );
};
