import { Suspense } from 'react';
import { Route, Switch, Redirect } from '@leagueplatform/routing';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { ExternalRedirect } from '@leagueplatform/web-common-components';
import {
  CareCollectionsRoutes,
  CareDiscoveryRoutes,
  CareDocumentsRoutes,
  FamilyExperienceRoutes,
  HealthJourneyRoutes,
  Home,
  LiveChatRoutes,
  MessagingRoutes,
  WalletRoutes,
} from 'components/lazy-routes.component';
import { LandingPage } from 'pages/landing.page';
import {
  PrivacyPolicyPage,
  TermsOfUsePage,
} from 'pages/agreement-content/tou-and-pp-agreement-content.pages';
import { TbkLesson } from 'pages/tbk/tbk.page';
import { PersonalInformationPage } from 'pages/settings/personal-information/personal-information.page';
import { AboutPage } from 'pages/about/about.page';
import { CommunicationPreferencesPage } from 'pages/settings/communication-preferences/communication-preferences.page';
import { AuthSignIn } from 'pages/auth/auth-sign-in.page';
import { AuthSignOut } from 'pages/auth/auth-sign-out.page';
import { HealthAuthorizationPage } from 'pages/settings/health-info-authorization/health-info-authorization.page';
import { CallbackRequestPage } from 'pages/callback-request/callback-request.page';
import { SignHealthAuthorizationPage } from 'pages/sign-health-authorization/sign-health-authorization.page';
import {
  OPTUM_FAQ,
  OPTUM_PRIVACY_POLICY_LINK,
  OPTUM_TERMS_CONDITION_LINK,
  TERMS_OF_USE_ROUTE,
  PRIVACY_POLICY_ROUTE,
  VIEW_HEALTH_INFORMATION_AUTHORIZATION_PATH,
  PATHS,
} from 'common/constants';
import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';
import { AuthSessionContainer } from 'components/auth-session-container/auth-session-container';
import { PrivateRoutesContainer } from 'components/private-routes-container/private-routes-container';
import { useFeatureFlags } from 'hooks/use-feature-flags.hook';
import { FEATURE_FLAGS } from 'common/firebase';
import { FlagProtectedComponent } from 'components/flag-protected-component/flag-protected-component.component';
import { EPMPPage } from 'pages/epmp/epmp.page';
import { BasePageProvider } from 'components/base-page-provider/base-page-provider.component';

export function AppRoutes() {
  const { featureFlags, isLoading: featureFlagsAreLoading } = useFeatureFlags([
    FEATURE_FLAGS.VIEW_GINA,
    FEATURE_FLAGS.LIVE_CHAT,
    FEATURE_FLAGS.CONSENT_MANAGEMENT,
    FEATURE_FLAGS.EPMP_PREFERENCE,
  ]);

  const {
    [FEATURE_FLAGS.VIEW_GINA]: viewGinaIsEnabled,
    [FEATURE_FLAGS.LIVE_CHAT]: liveChatIsEnabled,
    [FEATURE_FLAGS.CONSENT_MANAGEMENT]: consentManagementIsEnabled,
    [FEATURE_FLAGS.EPMP_PREFERENCE]: epmpPreferenceIsEnabled,
  } = featureFlags;

  return (
    <Suspense fallback={<FullPageLoadingSpinner />}>
      <Switch>
        <Route path="/sign-in" component={AuthSignIn} />
        <Route path="/sign-out" component={AuthSignOut} />
        <Route path={TERMS_OF_USE_ROUTE}>
          <FlagProtectedComponent
            flag={consentManagementIsEnabled}
            isLoading={featureFlagsAreLoading}
            componentRenderedWhenTrue={<TermsOfUsePage />}
            componentRenderedWhenFalse={
              <ExternalRedirect to={OPTUM_TERMS_CONDITION_LINK} />
            }
          />
        </Route>
        <Route path={PRIVACY_POLICY_ROUTE}>
          <FlagProtectedComponent
            flag={consentManagementIsEnabled}
            isLoading={featureFlagsAreLoading}
            componentRenderedWhenTrue={<PrivacyPolicyPage />}
            componentRenderedWhenFalse={
              <ExternalRedirect to={OPTUM_PRIVACY_POLICY_LINK} />
            }
          />
        </Route>
        <Route path="/" exact component={LandingPage} />
        <AuthSessionContainer>
          <PrivateRoutesContainer>
            <BasePageProvider>
              <Route path="/home" exact component={Home} />
              {/* TODO: Coordinate with marketing and iOS to remove the /member sub-directory from the URLs and remove these redirects. */}
              <Route exact path="/member/health-progress">
                <Redirect
                  to={getModulePath(LEAGUE_MODULE_NAMES.healthProgress)}
                />
              </Route>
              <Route exact path="/member/health-journey">
                <Redirect
                  to={getModulePath(LEAGUE_MODULE_NAMES.healthJourney)}
                />
              </Route>
              <Route exact path="/member/health-programs">
                <Redirect
                  to={getModulePath(LEAGUE_MODULE_NAMES.healthPrograms)}
                />
              </Route>
              <Route exact path="/member/get-care">
                <Redirect
                  to={getModulePath(LEAGUE_MODULE_NAMES.careDiscovery)}
                />
              </Route>
              <Route
                path={[
                  getModulePath(LEAGUE_MODULE_NAMES.healthJourney),
                  getModulePath(LEAGUE_MODULE_NAMES.healthPrograms),
                  getModulePath(LEAGUE_MODULE_NAMES.healthProgress),
                  getModulePath(LEAGUE_MODULE_NAMES.healthJourneyHistory),
                ]}
                component={HealthJourneyRoutes}
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.wallet)}
                component={WalletRoutes}
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.careCollections)}
                component={CareCollectionsRoutes}
              />
              <Switch>
                <Route
                  path={getModulePath(LEAGUE_MODULE_NAMES.careDocuments)}
                  component={CareDocumentsRoutes}
                />
                <Route
                  path={getModulePath(LEAGUE_MODULE_NAMES.careDiscovery)}
                  component={CareDiscoveryRoutes}
                />
              </Switch>
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.messaging)}
                component={MessagingRoutes}
              />
              <Route path={getModulePath(LEAGUE_MODULE_NAMES.familyExperience)}>
                <FamilyExperienceRoutes appName="optum" />
              </Route>
              <Route
                path="/settings/personal-information"
                component={PersonalInformationPage}
              />
              <Route path="/callback-request" component={CallbackRequestPage} />
              <Switch>
                <Redirect from="/about-care-advisor" to={PATHS.ABOUT} exact />
                <Route path={PATHS.ABOUT} component={AboutPage} />
              </Switch>
              <Route
                path={PATHS.COMMUNICATION_PREFERENCES}
                component={CommunicationPreferencesPage}
                exact
              />
              <Route
                path="/client-optum-tbk/courses/:courseIdentifier/lessons/:lessonIdentifier"
                component={TbkLesson}
              />
              {epmpPreferenceIsEnabled && (
                <Route path={PATHS.EPMP} exact component={EPMPPage} />
              )}
              {viewGinaIsEnabled && (
                <Route
                  path={VIEW_HEALTH_INFORMATION_AUTHORIZATION_PATH}
                  component={HealthAuthorizationPage}
                />
              )}
              {liveChatIsEnabled && (
                <Route
                  path={[
                    getModulePath(LEAGUE_MODULE_NAMES.liveChat),
                    // Live Chat deeplink included in emails, to align with mobile platforms
                    '/member/live-chat',
                  ]}
                  component={LiveChatRoutes}
                />
              )}
              {consentManagementIsEnabled && (
                <Route
                  path={PATHS.SIGN_HEALTH_AUTHORIZATION}
                  component={SignHealthAuthorizationPage}
                />
              )}
              {/* External redirects used for mobile deeplinking */}
              <Route path="/faq">
                <ExternalRedirect to={OPTUM_FAQ} />
              </Route>
            </BasePageProvider>
          </PrivateRoutesContainer>
        </AuthSessionContainer>
      </Switch>
    </Suspense>
  );
}
