import { useEffect } from 'react';
import { captureAppMessage } from '@leagueplatform/observability';
import { AUTH_VALIDATION_ERRORS } from 'common/constants';
import type { UseAuthReturn } from '@leagueplatform/auth-utils';
import { AuthError } from 'common/types';

export const useCaptureAuthError = ({
  isAuthenticated,
  error,
}: {
  isAuthenticated: UseAuthReturn['isAuthenticated'];
  error: AuthError;
}) => {
  useEffect(() => {
    // Auth errors that shouldn't be captured
    const ignoredErrors = [
      AUTH_VALIDATION_ERRORS.DELETED_USER,
      AUTH_VALIDATION_ERRORS.INELIGIBLE_USER,
    ];

    if (error && !ignoredErrors.some((err) => err === error?.description)) {
      const { type, description } = error;
      captureAppMessage(`Authentication Error: ${description}`, {
        tags: {
          type,
          description,
          authenticated: isAuthenticated,
        },
      });
    }
  }, [error, isAuthenticated]);
};
