import {
  StackLayout,
  HeadingText,
  ParagraphText,
} from '@leagueplatform/genesis-core';

interface Props {
  title: string;
  description: string;
}

export const AccountSetupTopSection = ({ title, description }: Props) => (
  <StackLayout
    horizontalAlignment="center"
    css={{
      marginTop: '$oneAndHalf',
      marginBottom: '$oneAndHalf',
    }}
  >
    <HeadingText level="1" size="xl">
      {title}
    </HeadingText>
    <ParagraphText size="sm" css={{ marginTop: '$one' }}>
      {description}
    </ParagraphText>
  </StackLayout>
);
