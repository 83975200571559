import { useIntl } from '@leagueplatform/locales';
import {
  HeadingText,
  StackLayout,
  TextAction,
} from '@leagueplatform/genesis-core';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  SCREEN_NAMES,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import { PATHS, EPMP_DISPLAY_QUERY_PARAM } from 'common/constants';

const editContactInformationOnClickAnalyticsEvent = () =>
  trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
    product_area: PRODUCT_AREA.SETTINGS,
    screen_name: SCREEN_NAMES.PERSONAL_INFO,
    detail: 'edit contact information',
  });

// Extracting shared styles is technically not necessary yet with one Action.
// For more Actions we can use TextAction with shared styles or write a reusable <li> component.
const actionLinkStyles = {
  display: 'flex',
  width: '100%',
  'svg:nth-child(1)': {
    marginRight: '$half',
  },
  'svg:nth-child(2)': {
    marginLeft: 'auto',
  },
};

export const PersonalInfoActions = () => {
  const { formatMessage } = useIntl();

  return (
    <StackLayout
      as="ul"
      spacing="$oneAndHalf"
      horizontalAlignment="stretch"
      css={{
        marginTop: '$oneAndHalf',
        padding: '$oneAndHalf',
        borderRadius: '$large',
        borderWidth: '$thin',
        borderStyle: 'solid',
        borderColor: '$onSurfaceBorderSubdued',
      }}
    >
      <HeadingText level="2" size="md">
        {formatMessage({ id: 'ACTIONS' })}
      </HeadingText>
      <li>
        <TextAction
          href={`${PATHS.EPMP}?display=${EPMP_DISPLAY_QUERY_PARAM.CONTACT}`}
          target="_blank"
          icon="interfaceEdit"
          size="medium"
          indicator="externalLink"
          css={actionLinkStyles}
          onClick={editContactInformationOnClickAnalyticsEvent}
        >
          {formatMessage({ id: 'EDIT_CONTACT_INFORMATION' })}
        </TextAction>
      </li>
    </StackLayout>
  );
};
