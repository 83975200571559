import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const ACCEPT_MEMBER_TERMS_MESSAGE_TYPE = 'accept_member_terms';

export const acceptMemberTerms = () =>
  SocketAsFetch.fetch({
    message_type: ACCEPT_MEMBER_TERMS_MESSAGE_TYPE,
    info: {
      version: '1',
    },
  });
