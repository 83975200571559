import {
  Box,
  css,
  Flex,
  genesisStyled,
  PrimaryButton,
  SubtleButton,
} from '@leagueplatform/genesis-commons';

const Container = genesisStyled(Flex)(
  css({
    position: 'fixed',
    left: '0',
    bottom: '0',
    width: '100%',
    height: 'five',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'surface.background.primary',
    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
  }),
);

interface Props {
  isLoading: boolean;
  acceptText: string;
  onAccept: () => void;
  backButton?: {
    onBack: () => void;
    text: string;
  };
}

export const ConsentFooter = ({
  isLoading,
  acceptText,
  onAccept,
  backButton,
}: Props) => (
  <Container>
    <Flex
      width={1}
      height="three"
      maxWidth="1200px"
      justifyContent="end"
      alignItems="center"
    >
      <Box>
        {backButton && (
          <SubtleButton
            marginX="half"
            type="button"
            onClick={backButton.onBack}
          >
            {backButton.text}
          </SubtleButton>
        )}
        <PrimaryButton
          isLoading={isLoading}
          marginX="half"
          type="submit"
          onClick={onAccept}
        >
          {acceptText}
        </PrimaryButton>
      </Box>
    </Flex>
  </Container>
);
