import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';
import type { FeatureFlag } from 'common/types';

/** Conditionally renders a component based on a flag that loads */
export const FlagProtectedComponent = ({
  flag,
  isLoading,
  componentRenderedWhenTrue,
  componentRenderedWhenFalse,
}: {
  flag: FeatureFlag;
  isLoading: boolean;
  componentRenderedWhenTrue: JSX.Element;
  componentRenderedWhenFalse: JSX.Element;
}): JSX.Element => {
  if (isLoading) return <FullPageLoadingSpinner />;

  return flag ? componentRenderedWhenTrue : componentRenderedWhenFalse;
};
