import {
  HeadingText,
  Button,
  ParagraphText,
  Modal,
  ImageMessage,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import errorImg from 'assets/error-icon.svg';
import { BaseModalContainer } from 'components/modals/base-modal.component';

export const ProfileImageErrorModal = () => {
  const { formatMessage } = useIntl();

  return (
    <BaseModalContainer
      maxWidth={380}
      footer={
        <Modal.Close>
          <Button priority="primary" width="fillContainer">
            Try again
          </Button>
        </Modal.Close>
      }
    >
      <ImageMessage
        image={errorImg}
        imageWidth={146}
        css={{ flexGrow: 1, padding: '$none $one', textAlign: 'center' }}
      >
        <Modal.Title>
          <HeadingText level="2" size="lg">
            {formatMessage({ id: 'PROFILE_IMAGE_UPLOAD_ERROR' })}
          </HeadingText>
        </Modal.Title>
        <ParagraphText emphasis="subtle" css={{ marginBlock: '$one $two' }}>
          {formatMessage({ id: 'PLEASE_TRY_AGAIN' })}
        </ParagraphText>
      </ImageMessage>
    </BaseModalContainer>
  );
};
