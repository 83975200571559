// Onboarding asset overrides
import onboardingFeatureHighlightBg from 'assets/onboarding-feature-highlight-bg.png';
import journeyHeaderBackground from 'assets/journey-header-background.svg';

// Journey asset overrides
import completeHighFive from 'assets/complete-high-five.svg';
import emptyStateRest from 'assets/empty-state-rest.svg';
import emptyStatePrograms from 'assets/empty-state-programs.svg';
import errorIcon from 'assets/error-icon.svg';

const JOURNEY_ASSETS = {
  EMPTY_STATE_REST: emptyStateRest,
  HIGH_FIVE: completeHighFive,
  EMPTY_STATE_PROGRAMS: emptyStatePrograms,
  JOURNEY_HEADER_BACKGROUND_IMAGE: journeyHeaderBackground,
};

const ONBOARDING_ASSETS = {
  FEATURE_HIGHLIGHT_BACKGROUND: onboardingFeatureHighlightBg,
};

const WEB_COMMON_ASSETS = {
  WEB_COMMON_ERROR_STATE_IMAGE: errorIcon,
};

export const ASSETS_CONFIG = {
  ...JOURNEY_ASSETS,
  ...ONBOARDING_ASSETS,
  ...WEB_COMMON_ASSETS,
};
