import { leagueFetch } from '@leagueplatform/league-fetch';
import { REST_ENDPOINTS } from 'common/constants';

export const getCallbackStatus = async () => {
  const response = await leagueFetch(REST_ENDPOINTS.CALLBACKS);

  if (response.ok) {
    const data = await response.json();
    return data?.data?.attributes?.callback_state;
  }

  throw new Error();
};
