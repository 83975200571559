import React from 'react';
import {
  Button,
  HeadingText,
  HeadingBar,
  Icon,
  Modal,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

interface Props {
  children: React.ReactNode;
  footer?: React.ReactNode;
  title?: string;
  maxWidth?: number;
}

export const BaseModalContainer = ({
  title,
  maxWidth = 583,
  children,
  footer = null,
}: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Modal.Content
      showCloseButton={false}
      padding="$none"
      layout={{ '@initial': 'standard', '@mobile': 'fullscreen' }}
      css={{
        '.GDS-modal-content': {
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        },
        '.GDS-modal': {
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth,
          '@mobile': {
            maxWidth: '100%',
          },
        },
      }}
    >
      <HeadingBar
        rightAction={
          <Modal.Close>
            <Button
              priority="tertiary"
              quiet
              icon="tinyClose"
              hideLabel
              size="small"
            >
              <Icon
                icon="tinyClose"
                size={16}
                label={formatMessage({ id: 'CLOSE' })}
              />
            </Button>
          </Modal.Close>
        }
        title={
          title ? (
            <Modal.Title>
              <HeadingText level="display" size="sm">
                {title}
              </HeadingText>
            </Modal.Title>
          ) : null
        }
      />
      <StackLayout
        css={{
          flexGrow: 1,
          textAlign: 'left',
          '@mobile': {
            textAlign: 'center',
          },
        }}
        spacing="$half"
        horizontalAlignment="center"
      >
        <StackLayout
          spacing="$oneAndHalf"
          horizontalAlignment="stretch"
          css={{
            padding: '$oneAndHalf',
            paddingTop: '$none',
            width: '100%',
          }}
        >
          {children}
          {footer}
        </StackLayout>
      </StackLayout>
    </Modal.Content>
  );
};
