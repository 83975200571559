import { leagueFetch } from '@leagueplatform/league-fetch';
import { REST_ENDPOINTS } from 'common/constants';

export const deleteUserAccount = async (userId: string) => {
  const response = await leagueFetch(`${REST_ENDPOINTS.USERS}${userId}`, {
    method: 'DELETE',
  });

  if (response.ok) {
    return true;
  }

  throw new Error(`deleteUser error: ${response.status}`);
};
