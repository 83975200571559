/* eslint-disable react/jsx-props-no-spreading */
import { useHistory } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import { MainPageContainer } from '@leagueplatform/web-common-components';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { HeroSection } from 'components/hero-section/hero-section.component';
import { DescriptionCard } from 'components/cards/description-card.component';
import { BodyContainer } from 'components/body-container/body-container.component';
import aboutImage from 'assets/about-optum-guide-image.svg';

export const AboutPage = () => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'ABOUT' }));
  const image = {
    src: aboutImage,
    width: '160px',
    alt: 'care advisor connect',
  };

  return (
    <MainPageContainer width="100%">
      <HeroSection
        title={formatMessage({ id: 'ABOUT_OPTUM_GUIDE' })}
        goBack={history.goBack}
      />
      <BodyContainer>
        <DescriptionCard
          description={formatMessage({
            id: 'ABOUT_OPTUM_GUIDE_DESCRIPTION',
          })}
          image={image}
        />
      </BodyContainer>
    </MainPageContainer>
  );
};
