import {
  OPTUM_FAQ,
  PRIVACY_POLICY_ROUTE,
  TERMS_OF_USE_ROUTE,
  VIEW_HEALTH_INFORMATION_AUTHORIZATION_PATH,
  PATHS,
} from 'common/constants';
import { useFeatureFlags } from 'hooks/use-feature-flags.hook';
import { FEATURE_FLAGS } from 'common/firebase';
import type { Link } from 'common/types';

export type Options = {
  heading?: string;
  items: Link[];
};

export const useUserDropdownConfig = (
  handleDeleteAccount: () => void,
): Options[] => {
  const {
    featureFlags: {
      [FEATURE_FLAGS.CONSENT_MANAGEMENT]: consentManagementIsEnabled,
    },
  } = useFeatureFlags([FEATURE_FLAGS.CONSENT_MANAGEMENT]);

  return [
    {
      heading: 'ACCOUNT_SETTINGS',
      items: [
        {
          text: 'PERSONAL_INFO',
          to: '/settings/personal-information',
        },
        {
          text: 'COMMUNICATION_PREFERENCES',
          to: '/communication-preferences',
        },
        {
          text: 'PERSONAL_CARE_TEAM',
          to: '/personal-care-team/members',
        },
        {
          text: 'SHARING_PERMISSIONS',
          to: '/personal-care-team/permissions',
        },
      ],
    },
    {
      heading: 'SUPPORT',
      items: [
        {
          text: 'FAQ',
          target: '_blank',
          to: `${OPTUM_FAQ}`,
        },
      ],
    },
    {
      heading: 'ABOUT',
      items: [
        {
          text: 'ABOUT_OPTUM_GUIDE',
          to: PATHS.ABOUT,
        },
        ...(consentManagementIsEnabled
          ? [
              {
                text: 'VIEW_HEALTH_INFORMATION_AUTHORIZATION',
                to: VIEW_HEALTH_INFORMATION_AUTHORIZATION_PATH,
              },
            ]
          : []),
        {
          text: 'PRIVACY_POLICY',
          to: PRIVACY_POLICY_ROUTE,
          target: '_blank',
        },
        {
          text: 'TERMS_OF_SERVICE',
          to: TERMS_OF_USE_ROUTE,
          target: '_blank',
        },
        {
          text: 'DELETE_ACCOUNT',
          onClick: handleDeleteAccount,
        },
      ],
    },
    {
      items: [
        {
          text: 'LOG_OUT',
          to: '/sign-out',
          color: '$onSurfaceTextCritical',
        },
      ],
    },
  ];
};
