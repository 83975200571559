import { GDSDecorativeTokens } from '@leagueplatform/genesis-core';

const decorative: GDSDecorativeTokens = {
  brand: {
    primary: {
      darkest: '#001D5B',
      dark: '#003A8D',
      default: '#0C55B8',
      bright: '#B0E3EA',
      brightest: '#D9F6FA',
      pastel: '#EEF4FF',
    },
    secondary: {
      darkest: '#222222',
      dark: '#CC4E22',
      default: '#FF612B',
      bright: '#FF8155',
      brightest: '#FFA080',
      pastel: '#FBF9F4',
    },
    tertiary: {
      darkest: '#40305E',
      dark: '#422C88',
      default: '#007C89',
      bright: '#A088CD',
      brightest: '#B3A0D7',
      pastel: '#E6DFF2',
    },
  },
};

export const decorativeTokens = {
  decorative,
};
