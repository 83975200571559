// TODO: Import only as types once SDK is updated
import {
  LiveChatClient,
  EventTypes,
  ChatEndedReason,
  ChatEndedReasonTypes,
  AgentChatEvents,
} from '@leagueplatform/live-chat';
import type { Message } from 'cometd';
import {
  LIVE_CHAT,
  LIVE_CHAT_EVENTS,
  LIVE_CHAT_OPERATIONS,
  LIVE_CHAT_QUIT_CODES,
} from './constants';
import { CometDLiveChatExtension } from './cometd-live-chat-extension';

type Participant = {
  participantId: number;
  nickname: string;
  type: string;
};
type MessageData = {
  type: string;
  index: string;
  from: Participant;
  utcTime: Date;
  text?: string;
};

export const getParticipantDetails = (
  from: Participant,
): { id: string; name: string } => ({
  id: from?.participantId?.toString(),
  name: from?.nickname,
});

const optumLiveChat = new CometDLiveChatExtension();

export const assignQuitReason = (quitCode?: string): ChatEndedReason => {
  let quitReason: ChatEndedReason;

  switch (quitCode) {
    case LIVE_CHAT_QUIT_CODES.LOGOUT_REQUEST:
      quitReason = { type: ChatEndedReasonTypes.USER_DISCONNECTED };
      break;
    case LIVE_CHAT_QUIT_CODES.CHAT_PROTOCOL_INACTIVITY:
    case LIVE_CHAT_QUIT_CODES.CHAT_SESSION_INACTIVITY:
    case LIVE_CHAT_QUIT_CODES.REMOVED_DURING_RESTORATION:
      quitReason = { type: ChatEndedReasonTypes.CONNECTION_LOST };
      break;
    case LIVE_CHAT_QUIT_CODES.PARTICIPANT_REMOVED_PARTICIPANT:
      quitReason = { type: ChatEndedReasonTypes.AGENT_DISCONNECTED };
      break;
    case LIVE_CHAT_QUIT_CODES.SERVER_REMOVED_PARTICIPANT:
      quitReason = {
        type: ChatEndedReasonTypes.CHAT_UNAVAILABLE,
        // TODO: Localize these strings
        // https://everlong.atlassian.net/jira/software/c/projects/OPTM/boards/559/backlog?selectedIssue=OPTM-4069
        errorTitle: LIVE_CHAT.CLOSED_TITLE,
        errorMessage: LIVE_CHAT.CLOSED_MESSAGE,
      };
      break;
    default:
      quitReason = {
        type: ChatEndedReasonTypes.CHAT_UNAVAILABLE,
      };
  }
  return quitReason;
};

export const handleChatEvent = (
  message: MessageData,
  cb: (event: AgentChatEvents) => void,
) => {
  const { type, index, from, utcTime, text } = message;
  const id = `chat-event-${index.toString()}`;

  const isClient = from?.type === LIVE_CHAT.CLIENT;
  const isAgent = from?.type === LIVE_CHAT.AGENT;

  if (!isClient) {
    switch (type) {
      case LIVE_CHAT_EVENTS.TYPING_STARTED:
      case LIVE_CHAT_EVENTS.TYPING_STOPPED:
        // Ignore user typing events, since we don't need them shown in our UI
        cb({
          eventType: EventTypes.PARTICIPANT_TYPING,
          eventId: id,
          isTyping: type === LIVE_CHAT_EVENTS.TYPING_STARTED,
        });
        break;
      case LIVE_CHAT_EVENTS.MESSAGE:
      case LIVE_CHAT_EVENTS.PUSH_URL:
        if (optumLiveChat.chatInActiveState && optumLiveChat.agentsOnline > 0) {
          cb({
            eventId: id,
            eventType: EventTypes.MESSAGE,
            sender: getParticipantDetails(from),
            timestamp: utcTime,
            message: text,
          });
        } else {
          cb({
            eventId: id,
            eventType: EventTypes.WAITING_ROOM,
            title: text,
          });
        }
        break;
      case LIVE_CHAT_EVENTS.PARTICIPANT_JOINED:
        // If an agent joins, move out of the waiting room
        // We don't want to show joined events for 'External'
        if (isAgent) {
          optumLiveChat.agentsOnline += 1;

          if (!optumLiveChat.chatInActiveState) {
            optumLiveChat.chatInActiveState = true;

            cb({
              eventType: EventTypes.CHAT_STARTED,
              eventId: 'chat-event-start',
            });
          }

          cb({
            eventId: id,
            eventType: EventTypes.PARTICIPANT_JOINED,
            participant: getParticipantDetails(from),
          });
        }

        break;
      case LIVE_CHAT_EVENTS.PARTICIPANT_LEFT:
        if (isAgent) {
          optumLiveChat.agentsOnline -= 1;

          if (optumLiveChat.agentsOnline === 0) {
            optumLiveChat.chatInActiveState = false;
          }

          cb({
            eventId: id,
            eventType: EventTypes.PARTICIPANT_LEFT,
            participant: getParticipantDetails(from),
          });
        }
        break;
      default:
    }
  }
};

export const optumLiveChatExtension: LiveChatClient = {
  subscribeToAgentChatEvents: (updateMessagingSDK) => {
    // We need to keep track of whether chat is active so we can tell when to fire WAITING_ROOM or CHAT_STARTED events
    optumLiveChat.channelListener = optumLiveChat.cometD.addListener(
      LIVE_CHAT.CHANNEL,
      (res: Message) => {
        const { data } = res;
        // console.log(data);
        const { messages, secureKey, chatEnded } = data;
        let quitReason: ChatEndedReason = assignQuitReason();

        if (secureKey) {
          optumLiveChat.secureKey = secureKey;
        }

        if (messages.length) {
          const { eventAttributes } = messages[0];
          const quitCode = eventAttributes?.GCTI_SYSTEM?.['quit-reason-code'];

          quitReason = assignQuitReason(quitCode);

          handleChatEvent(messages[0], updateMessagingSDK);
        }

        if (chatEnded) {
          updateMessagingSDK({
            eventId: 'chat-event-end',
            eventType: EventTypes.CHAT_ENDED,
            reason: quitReason,
          });
          optumLiveChat.disconnectChat();
          optumLiveChat.chatInActiveState = false;
        }
      },
    );
  },
  handleUserChatEvents: (event) => {
    // console.log(event);
    switch (event.eventType) {
      case EventTypes.START_CHAT:
        if (event.config.token) optumLiveChat.startChat(event.config);
        break;
      case EventTypes.END_CHAT:
        if (optumLiveChat.connected) {
          optumLiveChat.disconnectChat();
        }
        optumLiveChat.cometD.publish(LIVE_CHAT.CHANNEL, {
          operation: LIVE_CHAT_OPERATIONS.DISCONNECT,
          secureKey: optumLiveChat.secureKey,
        });
        break;
      case EventTypes.MESSAGE:
        optumLiveChat.cometD.publish(LIVE_CHAT.CHANNEL, {
          operation: LIVE_CHAT_OPERATIONS.SEND_MESSAGE,
          message: event.message,
          secureKey: optumLiveChat.secureKey,
        });
        break;
      case EventTypes.PARTICIPANT_TYPING:
        if (event.isTyping) {
          optumLiveChat.cometD.publish(LIVE_CHAT.CHANNEL, {
            operation: LIVE_CHAT_OPERATIONS.START_TYPING,
            secureKey: optumLiveChat.secureKey,
          });
        } else {
          optumLiveChat.cometD.publish(LIVE_CHAT.CHANNEL, {
            operation: LIVE_CHAT_OPERATIONS.STOP_TYPING,
            secureKey: optumLiveChat.secureKey,
          });
        }
        break;
      default:
    }
  },
};
