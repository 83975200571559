import { useEffect } from 'react';
import { StackLayout } from '@leagueplatform/genesis-core';
import { useHistory } from '@leagueplatform/routing';
import { useModal } from 'hooks/use-modal.hook';
import { useAppAuthorization } from 'hooks/use-app-authorization.hook';
import { LandingPageBackgroundImageSection } from 'components/landing-page/background-img-section.component';
import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';
import { SignInSection } from 'components/landing-page/sign-in-section.component';
import { BasePage } from 'components/base-page';
import { AuthErrorModal } from 'components/modals/auth-error-modal.component';

export function LandingPage() {
  const { isAuthenticated, error, appState, isLoading } = useAppAuthorization();
  const { isShowing: showLoginModal, toggle: toggleLoginModal } = useModal();
  const history = useHistory();
  const loginError = error?.description;

  // Redirect the user once authenticated
  useEffect(() => {
    if (isAuthenticated) {
      history.push({
        pathname: appState?.redirectPath || '/home',
      });
    }
  }, [history, isAuthenticated, appState]);

  // Show modal when an error occurs
  useEffect(() => {
    if (loginError) {
      toggleLoginModal();
    }
  }, [loginError, toggleLoginModal]);

  return (
    <BasePage showNavigation={false}>
      {loginError && (
        <AuthErrorModal
          showModal={showLoginModal}
          toggleModal={toggleLoginModal}
          error={loginError}
        />
      )}
      <StackLayout
        verticalAlignment="stretch"
        horizontalAlignment="stretch"
        orientation={{
          '@initial': 'horizontal',
          '@mobile': 'vertical',
          '@mobileLandscape': 'vertical',
        }}
      >
        {isLoading && <FullPageLoadingSpinner />}
        {isAuthenticated !== undefined && (
          <>
            <LandingPageBackgroundImageSection />
            <SignInSection />
          </>
        )}
      </StackLayout>
    </BasePage>
  );
}
