import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { SENTRY_SEVERITY_LEVELS } from 'common/types';
import { MESSAGE_TYPES, SENTRY_CONSTANTS } from 'common/constants';
import { captureAppMessage } from '@leagueplatform/observability';

export const getTBKAuth = async () => {
  try {
    const response = await SocketAsFetch.fetch({
      message_type: MESSAGE_TYPES.GET_TBK_AUTH_MESSAGE_TYPE,
    });

    return response;
  } catch (e) {
    captureAppMessage(`Failed to get TBK auth`, {
      ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
      severityLevel: SENTRY_SEVERITY_LEVELS.ERROR,
      errorName: (e as Error)?.message || 'error retrieving TBK auth token',
    });
  }

  return null;
};
