import { captureAppError } from '@leagueplatform/observability';
import { getAppInitDetails } from 'api/get-app-init-details';
import { SENTRY_CONSTANTS } from 'common/constants';

export const getUserEligibility = async (
  callback: (isEligible: boolean) => void,
  isAuthenticated: boolean,
) => {
  try {
    const appInitDetails = await getAppInitDetails();
    callback(appInitDetails?.eligible);
  } catch (e) {
    captureAppError(
      new Error('Failed to fetch user eligibility via get_app_init_details'),
      {
        context: {
          Context: {
            authenticated: isAuthenticated,
            message: e,
          },
        },
        ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
        tags: {
          authenticated: isAuthenticated,
        },
      },
    );
  }
};
