import { Checkbox, GDSCheckboxProps } from '@leagueplatform/genesis-core';

interface Props extends Omit<GDSCheckboxProps, 'id'> {
  handleCheck?: () => void;
  ariaDisabled?: boolean;
}

export const CheckConfirmation = ({
  label,
  name,
  checked = false,
  loading = false,
  handleCheck,
  hint,
  disabled,
  ariaDisabled,
}: Props) => (
  <Checkbox
    onChange={handleCheck}
    id={name}
    name={name}
    checked={checked}
    label={label}
    loading={loading}
    hint={hint}
    disabled={disabled}
    aria-disabled={ariaDisabled}
    css={{
      '.GDS-checkbox-label': {
        position: 'relative',
        left: '-$half',
        alignItems: 'flex-start',
        gap: '$one',
      },
      '.GDS-checkbox-label-text': {
        fontSize: `var(--GDS-fontSizes-bodyTwo)`,
        lineHeight: `var(--GDS-lineHeights-bodyTwo)`,
        paddingTop: 2,
      },
      '.GDS-checkbox-input': { position: 'relative' },
      '.GDS-checkbox-input::before': {
        position: 'absolute',
        width: '100%',
        height: '100%',
      },
      '.GDS-checkbox-hint': {
        marginBlockStart: '$quarter',
      },
    }}
  />
);
