import { StandaloneAuth } from '@leagueplatform/auth-standalone';

export const logoutAndRemoveLocalStorage = () => {
  StandaloneAuth.client
    .logout({
      federated: true,
      returnTo: window.location.origin,
    })
    .then(() => {
      // Clear these localstorage items on logout
      [
        'tbkUserInfo',
        'ajs_user_id',
        'ajs_user_traits',
        'ajs_anonymous_id',
      ].forEach((key) => window.localStorage.removeItem(key));
    });
};
