export const APP_ID = 'optum-web';

/**
 * @deprecated TEMPORARY while we transition from client-set TenantID to server-set
 */
export const TENANT_ID = 'optum';

export const OPTUM_CARE_ADVISOR_URL = 'https://careadvisor.optum.com/s';
export const OPTUM_FAQ =
  'https://careadvisorconnect.league.com/?t=f2vSo1HdssFIoow_RtKWkA';

export const OPTUM_TERMS_CONDITION_LINK = `${OPTUM_CARE_ADVISOR_URL}/terms-of-use`;
export const OPTUM_PRIVACY_POLICY_LINK = `${OPTUM_CARE_ADVISOR_URL}/privacy-policy`;
export const TERMS_OF_USE_ROUTE = `/terms-of-use`;
export const PRIVACY_POLICY_ROUTE = `/privacy-policy`;

export const CARE_ADVISOR_EMAIL = 'careadvisorconnect@optum.com';
export const CARE_ADVISOR_PHONE_NUMBER = '866-658-4644';
export const VIEW_HEALTH_INFORMATION_AUTHORIZATION_PATH =
  '/view-health-authorization';

// TODO [OPTM-3744] move all routes paths to this object
export const PATHS = {
  SIGN_HEALTH_AUTHORIZATION: '/sign-health-authorization',
  COMMUNICATION_PREFERENCES: '/communication-preferences',
  EPMP: '/communication-preferences/other',
  ABOUT: '/about-optum-guide',
};
/* Environments */

export const AUTH0_CLIENT_CONNECTION = 'optum';

export const NPSSurveyConfig = {
  interceptId: 'SI_2nKmq5YVHcToqma',
  brandId: 'uhg1',
  projectId: 'ZN_3WAcqHSsRfyx3b8',
};

// Custom Optum login validation errors
export const AUTH_VALIDATION_ERRORS = {
  // Auth0 errors
  // https://github.com/EverlongProject/services/blob/main/src/el/components/optum_profile/optum_login_validator/optum_validate_login.go#L13
  RETRIEVE_KEYCHAIN: 'unable_to_retrieve_keychain',
  RETRIEVE_USER: 'unable_to_retrieve_user',
  DELETED_USER: 'unable_to_login_deleted_user',
  // BE validated errors
  INELIGIBLE_USER: 'unable_to_login_ingeligible_user',
};

export const MASONRY = {
  API_VERSION: 1,
  HOME_PAGE_APP_ID: 'optum-web-homepage',
  CUSTOM_WIDGET_MODULE_KEY: 'optum',
  CUSTOM_WIDGET_TYPE_HEALTHLINK_LOGO: 'healthlink_logo',
  HEALTH_JOURNEY_MODULE_KEY: 'health_journey',
  CUSTOM_WIDGET_TYPE_HEALTH_JOURNEY: 'health_journey',
  MESSAGING_MODULE_KEY: 'messaging',
  CUSTOM_WIDGET_TYPE_MESSAGING: 'recent_messages',
};

export const APP_STORE = {
  APPLE: 'https://apps.apple.com/us/app/care-advisor-connect/id6446152350',
  GOOGLE:
    'https://play.google.com/store/apps/details?id=com.optum.careadvisorconnect',
};

export const COMMUNICATION_OPTIONS = {
  SMS: 'sms',
  EMAIL: 'email',
};

export const LINK_TYPES = {
  DROPOFF: 'dropoff',
};

export const SENTRY_CONSTANTS = {
  OWNERSHIP_TEAM: 'optum-web',
  NO_INFO_AVAILABLE: 'no info available',
};

export const MESSAGE_TYPES = {
  GET_APP_INIT_DETAILS: 'get_app_init_details',
  GET_WIDGET_CONTAINER: 'get_widget_container',
  GET_TBK_AUTH_MESSAGE_TYPE: 'authorize_tbk',
};

export const REST_ENDPOINTS = {
  CALLBACKS: 'v1/optum/callbacks',
  SSO_TOKEN: '/v1/sso/optum/token',
  USERS: 'v1/users/',
  USER_AGREEMENTS_STATUS: '/v1/optum/user-agreements-status',
  AGREEMENT_CONTENT: 'v1/optum/agreement-content/',
  PARTNER_USER_IDENTIFIERS: 'v1/optum/partner-user-identifiers',
  PARTNER_USER_COMMUNICATION_INFO: 'v1/optum/partner-user-communication-info',
};

export const CALLBACK_STATUS = {
  QUEUED: 'QUEUED',
  COMPLETED: 'COMPLETED',
};

export const SSO_TYPES = {
  UHC: 'uhc',
  RX: 'rx',
  CPV: 'cpvideo',
  BANK: 'bank',
};

export const AGREEMENT_STATUS_TYPES = {
  TOU: 'TOU', // Terms of Usage
  GINA: 'GINA',
} as const;

export const AGREEMENT_CONTENT_TYPES = {
  TERMS_OF_USE: 'TOU',
  PRIVACY_POLICY: 'PP',
  GINA: 'GINA',
} as const;

export const LIVE_CHAT = {
  CHANNEL: '/service/chatV2/OPT_PopulationHealthServices_Advocacy',
  HANDSHAKE_CHANNEL: '/meta/handshake',
  CLIENT: 'Client',
  AGENT: 'Agent',
  EXTERNAL: 'External',
  BOOKING_DEVICE_TYPE: 'Web',
  PORTAL_PAGE: 'Get Care',
  CLOSED_TITLE: 'Live Chat is currently closed',
  CLOSED_MESSAGE: 'Please come back at a later time',
};

export const LIVE_CHAT_EVENTS = {
  PARTICIPANT_JOINED: 'ParticipantJoined',
  PARTICIPANT_LEFT: 'ParticipantLeft',
  MESSAGE: 'Message',
  PUSH_URL: 'PushUrl',
  TYPING_STARTED: 'TypingStarted',
  TYPING_STOPPED: 'TypingStopped',
};

export const LIVE_CHAT_OPERATIONS = {
  REQUEST_CHAT: 'requestChat',
  SEND_MESSAGE: 'sendMessage',
  START_TYPING: 'startTyping',
  STOP_TYPING: 'stopTyping',
  DISCONNECT: 'disconnect',
};

export const REACCEPT_GINA_FLOW_STATUS = {
  PROMPT: 'prompt',
  CONTENT: 'content',
  CONFIRMATION: 'confirmation',
};

// See Genesys docs for reason codes: https://docs.genesys.com/Documentation/ESChat/latest/Admin/RC
export const LIVE_CHAT_QUIT_CODES = {
  LOGOUT_REQUEST: '0',
  CHAT_PROTOCOL_INACTIVITY: '3',
  PARTICIPANT_REMOVED_PARTICIPANT: '4',
  SERVER_REMOVED_PARTICIPANT: '5',
  CHAT_SESSION_INACTIVITY: '7',
  REMOVED_DURING_RESTORATION: '8',
};

export const EPMP_DISPLAY_QUERY_PARAM = {
  PREFERENCES: 'preferences',
  CONTACT: 'contact',
};

// Analytics screen_name values specific to Optum
export const SCREEN_NAMES = {
  EPMP_CONTACT_INFORMATION: 'epmp contact information',
  EPMP_PREFERENCES: 'epmp preferences',
};

// Top navigation height
export const APP_NAV_DEFAULT_HEIGHT = 8 * 8; // 64px – 8 lines on the Genesis 8px grid
