import { useEffect } from 'react';
import { logoutAndRemoveLocalStorage } from 'utils/logout';
import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';

export const AuthSignOut = () => {
  useEffect(() => {
    logoutAndRemoveLocalStorage();
  }, []);

  return <FullPageLoadingSpinner />;
};
