import type { JSX } from 'react';
import { MaintenancePage } from 'pages/maintenance-page/maintenance.page';
import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';
import { FEATURE_FLAGS } from 'common/firebase';
import { useFeatureFlags } from 'hooks/use-feature-flags.hook';

export const AppEntryWrapper = ({ children }: { children: JSX.Element }) => {
  const { featureFlags, isLoading: featureFlagsAreLoading } = useFeatureFlags([
    FEATURE_FLAGS.MAINTENANCE_PAGE,
  ]);
  const { [FEATURE_FLAGS.MAINTENANCE_PAGE]: maintenancePageFlag } =
    featureFlags;

  if (featureFlagsAreLoading) return <FullPageLoadingSpinner />;

  let maintenancePageIsEnabled;
  let endDate = '';

  // Parse the flag string as a JSON (try) and default to false if not a JSON (catch).
  try {
    if (typeof maintenancePageFlag === 'string') {
      const parsed = JSON.parse(maintenancePageFlag);
      maintenancePageIsEnabled = parsed?.enabled;

      // Pass the formatted date only if it's a valid date in the future.
      const date = new Date(parsed?.message_end_date);
      const now = new Date();
      if (!Number.isNaN(date.getDate()) && date.getTime() >= now.getTime())
        endDate = new Date(parsed?.message_end_date).toLocaleString('default', {
          month: 'short',
          day: 'numeric',
        });
    }
  } catch {
    maintenancePageIsEnabled = false;
  }

  if (maintenancePageIsEnabled) return <MaintenancePage endDate={endDate} />;

  return children;
};
