import {
  Button,
  StackLayout,
  TextAction,
  ParagraphText,
  styled,
  HeadingText,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { useHistory } from '@leagueplatform/routing';

import logo from 'assets/care-advisor-vertical-logo.svg';
import { PRIVACY_POLICY_ROUTE, TERMS_OF_USE_ROUTE } from 'common/constants';

const Image = styled('img', { maxWidth: '100%' });

export const SignInSection = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  return (
    <StackLayout
      orientation="vertical"
      horizontalAlignment="center"
      verticalAlignment="center"
      css={{
        alignSelf: 'center',
        justifyContent: 'center',
        width: '100%',
        maxWidth: '44.4%',
        '@mobile': { maxWidth: '100%' },
        '@mobileLandscape': { maxWidth: '100%' },
      }}
    >
      <HeadingText
        size="lg"
        level="1"
        css={{ margin: '2.4rem', lineHeight: 0 }}
      >
        <Image
          src={logo}
          width={270}
          height={165}
          alt={formatMessage({ id: 'CARE_ADVISOR_CONNECT' })}
        />
      </HeadingText>
      <Button
        width="fillContainer"
        css={{
          maxWidth: 327,
          marginBlock: '$twoAndHalf $two',
        }}
        onClick={() => history.push('/sign-in')}
      >
        {formatMessage({ id: 'CONTINUE_WITH_HSID' })}
      </Button>
      <ParagraphText
        size="sm"
        css={{
          textAlign: 'center',
          marginBlock: '$three',
          '& > a': { fontSize: 'inherit', textDecoration: 'underline' },
        }}
      >
        <TextAction
          href={PRIVACY_POLICY_ROUTE}
          target="_blank"
          rel="noreferrer"
        >
          {formatMessage({ id: 'PRIVACY_POLICY' })}
        </TextAction>
        <span> {formatMessage({ id: 'AND' })} </span>
        <TextAction href={TERMS_OF_USE_ROUTE} target="_blank" rel="noreferrer">
          {formatMessage({ id: 'TERMS_OF_SERVICE' })}
        </TextAction>
      </ParagraphText>
    </StackLayout>
  );
};
