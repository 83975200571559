import { useContext } from 'react';
import { getValue } from 'firebase/remote-config';
import type { Value } from 'firebase/remote-config';
import { remoteConfig } from 'common/firebase';
import { RemoteConfigContext } from 'components/remote-config-provider/remote-config-provider.component';
import type { FEATURE_FLAGS } from 'common/firebase';
import type { RemoteConfigContextType } from 'components/remote-config-provider/remote-config-provider.component';
import type { FeatureFlag } from 'common/types';

type IsEnabledFlags = {
  [key: string]: FeatureFlag;
};

type FeatureFlags = RemoteConfigContextType & {
  featureFlags: IsEnabledFlags;
};

const parseFlagValue = (value: Value) => {
  const valueString = value.asString();
  if (valueString === 'false') return false;
  if (valueString === 'true') return true;
  return valueString;
};

// Returns the values of the remote config feature flags
export const useFeatureFlags = (flags: FEATURE_FLAGS[]): FeatureFlags => {
  const { isLoading, isDefault } = useContext(RemoteConfigContext);

  const featureFlags: IsEnabledFlags = flags.reduce(
    (isEnabledFlags: IsEnabledFlags, flag: FEATURE_FLAGS) => ({
      ...isEnabledFlags,
      [flag]: parseFlagValue(getValue(remoteConfig, flag)),
    }),
    {},
  );

  return {
    featureFlags,
    isLoading,
    isDefault,
  };
};
