import { useQuery } from 'react-query';
import {
  getAgreementStatus,
  GetAgreementStatusParams,
} from 'api/get-agreement-status/get-agreement-status';
import { REST_ENDPOINTS } from 'common/constants';

export const useGetAgreementStatus = (params: GetAgreementStatusParams) =>
  useQuery(
    [REST_ENDPOINTS.USER_AGREEMENTS_STATUS],
    () => getAgreementStatus(params),
    {
      cacheTime: 0,
    },
  );
