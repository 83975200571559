import cometDLib from 'cometd';
import type { Message, ListenerHandle } from 'cometd';
import { LIVE_CHAT, LIVE_CHAT_OPERATIONS } from './constants';

type ChatConfig = {
  [key: string]: string;
};

export const formatDisplayName = ({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName?: string;
}) =>
  lastName ? `${firstName} ${lastName.charAt(0).toUpperCase()}` : firstName;

export const userChatConfig = (config: ChatConfig) => {
  // console.log(config);
  const { token, ...userData } = config;
  const {
    ENT_ContactFirstNm: firstName,
    ENT_ContactLastNm: lastName,
    ENT_SubFunction: subject,
  } = userData;

  userData.ENT_BookingDeviceType = LIVE_CHAT.BOOKING_DEVICE_TYPE;
  // this is a required KVP but since we're on web, leave it blank
  userData.ENT_DeviceOS = '';

  return {
    operation: LIVE_CHAT_OPERATIONS.REQUEST_CHAT,
    nickname: formatDisplayName({ firstName, lastName }),
    firstName,
    lastName,
    subject,
    userData,
  };
};

export class CometDLiveChatExtension {
  cometD: cometDLib.CometD;

  secureKey: string | undefined;

  agentsOnline: number;

  chatInActiveState: boolean;

  connected: boolean;

  handshakeListener!: ListenerHandle;

  channelListener!: ListenerHandle;

  constructor() {
    this.cometD = new cometDLib.CometD();
    this.agentsOnline = 0;
    this.secureKey = '';
    this.connected = false;
    this.chatInActiveState = false;
  }

  startChat(config: ChatConfig) {
    // console.log(config);
    const { token } = config;
    const url = `${
      import.meta.env.VITE_LIVE_CHAT_SERVER_URL
    }?access_token=${token}`;

    this.cometD.websocketEnabled = true;
    this.cometD.configure({
      url,
      // appending messages is not supported, so disable it
      appendMessageTypeToURL: false,
    });

    const handshake = (res: Message) => {
      const userConfig = {
        ...userChatConfig(config),
      };

      // TODO: What do we want to happen if the handshake fails
      if (res.successful) {
        this.cometD.publish(LIVE_CHAT.CHANNEL, userConfig);
      }
    };

    this.handshakeListener = this.cometD.addListener(
      LIVE_CHAT.HANDSHAKE_CHANNEL,
      handshake,
    );

    this.cometD.handshake();
  }

  disconnectChat() {
    this.connected = false;
    this.cometD.disconnect();
    this.cometD.removeListener(this.handshakeListener);
    this.cometD.removeListener(this.channelListener);
    this.secureKey = undefined;
  }
}
