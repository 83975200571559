import { REST_ENDPOINTS } from 'common/constants';
import type { AgreementContentTypes } from 'common/types';

const URL = `${import.meta.env.VITE_REST_API_URL}/${
  REST_ENDPOINTS.AGREEMENT_CONTENT
}`;

export const getAgreementContent = async (
  contentType: AgreementContentTypes,
) => {
  const response = await fetch(`${URL}${contentType}`);

  if (response.ok) {
    const data = await response.json();
    return data?.data?.attributes;
  }
  throw new Error();
};
