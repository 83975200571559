import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const ACCEPT_GINA_CONSENT_MESSAGE_TYPE = 'accept_gina_consent';

export const acceptGinaConsent = async () => {
  try {
    const response = await SocketAsFetch.fetch({
      message_type: ACCEPT_GINA_CONSENT_MESSAGE_TYPE,
      info: {
        version: '1',
      },
    });
    return response;
  } catch (error) {
    if (error instanceof Error) {
      const errorMessage = JSON.parse(error.message);
      /* eslint-disable */
      console.error(
        `"${ACCEPT_GINA_CONSENT_MESSAGE_TYPE}": we had a problem with this api call: ${errorMessage?.info?.reason}`,
      );
      /* eslint-enable */
    }
    throw error;
  }
};
