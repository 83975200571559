import { ReactNode, UIEvent } from 'react';
import { useHistory } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import {
  StackLayout,
  Button,
  Box,
  Scrollable,
} from '@leagueplatform/genesis-core';

interface LayoutProps {
  children: ReactNode;
  onClick: (event: UIEvent) => void;
  buttonText: string;
  isLoading?: boolean;
  buttonType?: 'button' | 'reset' | 'submit';
}

export const CallbackRequestLayout = ({
  children,
  onClick,
  buttonText,
  buttonType = 'button',
  isLoading,
}: LayoutProps) => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  return (
    <StackLayout
      css={{ background: '$surfaceBackgroundSecondary' }}
      horizontalAlignment="center"
    >
      <Box>
        <Button
          hideLabel
          icon="tinyClose"
          size="small"
          priority={{ '@initial': 'tertiary', '@mobile': 'primary' }}
          quiet
          onClick={() => history.goBack()}
          css={{
            position: 'absolute',
            top: '$two',
            right: '$two',
            zIndex: '$sticky',
            '@mobile': { top: '$one', right: '$one' },
          }}
        >
          {formatMessage({ id: 'CANCEL' })}
        </Button>
        <Scrollable
          css={{
            maxWidth: 484,
            background: '$surfaceBackgroundPrimary',
            borderRadius: '$large',
            height: 'calc(100vh - 232px)',
            marginTop: '$two',
            overflowY: 'auto',
            '@mobile': {
              marginTop: 0,
              height: 'calc(100vh - 148px)',
              borderRadius: '$none',
            },
          }}
        >
          {children}
        </Scrollable>
        <StackLayout
          horizontalAlignment="end"
          css={{
            padding: '$twoAndHalf 0 $twoAndHalf 0',
            '@mobile': { padding: '$one' },
          }}
        >
          <Button
            type={buttonType}
            loading={isLoading}
            disabled={isLoading}
            onClick={onClick}
            width={{
              '@initial': 'hugContents',
              '@mobile': 'fillContainer',
            }}
          >
            {buttonText}
          </Button>
        </StackLayout>
      </Box>
    </StackLayout>
  );
};
