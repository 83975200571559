import { useIntl } from '@leagueplatform/locales';
import { useHistory } from '@leagueplatform/routing';
import { Box, StackLayout } from '@leagueplatform/genesis-core';
import { MainPageContainer } from '@leagueplatform/web-common-components';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  SCREEN_NAMES,
  SUB_PRODUCT_AREA,
  trackAnalyticsEvent,
  usePageViewAnalytics,
} from '@leagueplatform/analytics';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';
import { HeroSection } from 'components/hero-section/hero-section.component';
import { usePersonalInformation } from 'hooks/use-personal-information.hook';
import { EditProfilePhoto } from 'components/forms/edit-profile-photo/edit-profile-photo.component';
import { EditDisplayName } from 'components/forms/edit-display-name/edit-display-name.component';
import { PersonalInfoActions } from 'components/personal-info-actions/personal-info-actions.component';

const updateProfilePhotoAnalyticsEvent = (hasProfilePhoto?: boolean) =>
  trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
    product_area: PRODUCT_AREA.SETTINGS,
    screen_name: SCREEN_NAMES.PERSONAL_INFO,
    detail: `${hasProfilePhoto ? 'change' : 'add'} profile photo`,
  });

const editDisplayNameButtonAnalyticsEvent = () =>
  trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
    product_area: PRODUCT_AREA.SETTINGS,
    screen_name: SCREEN_NAMES.PERSONAL_INFO,
    detail: 'edit display name',
  });

const saveDisplayNameButtonAnalyticsEvent = () =>
  trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
    product_area: PRODUCT_AREA.SETTINGS,
    sub_product_area: SUB_PRODUCT_AREA.PERSONAL_INFO,
    screen_name: SCREEN_NAMES.EDIT_DISPLAY_NAME,
    detail: 'save',
  });

const cancelDisplayNameButtonAnalyticsEvent = () =>
  trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
    product_area: PRODUCT_AREA.SETTINGS,
    sub_product_area: SUB_PRODUCT_AREA.PERSONAL_INFO,
    screen_name: SCREEN_NAMES.EDIT_DISPLAY_NAME,
    detail: 'cancel',
  });

export const PersonalInformationPage = () => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'PERSONAL_INFO' }));
  usePageViewAnalytics({
    product_area: PRODUCT_AREA.SETTINGS,
    screen_name: SCREEN_NAMES.PERSONAL_INFO,
  });

  const { isLoading } = usePersonalInformation();

  if (isLoading) {
    return <FullPageLoadingSpinner />;
  }
  return (
    <MainPageContainer width="100%">
      <HeroSection
        title={formatMessage({ id: 'PERSONAL_INFO' })}
        goBack={history.goBack}
      />
      <StackLayout horizontalAlignment="center">
        <Box
          css={{
            width: '100%',
            maxWidth: 370,
            padding: '$oneAndHalf',
          }}
        >
          <EditProfilePhoto
            fileInputOnClickCallback={updateProfilePhotoAnalyticsEvent}
          />
          <EditDisplayName
            editButtonOnClickCallback={editDisplayNameButtonAnalyticsEvent}
            saveButtonOnClickCallback={saveDisplayNameButtonAnalyticsEvent}
            cancelButtonOnClickCallback={cancelDisplayNameButtonAnalyticsEvent}
          />
          <PersonalInfoActions />
        </Box>
      </StackLayout>
    </MainPageContainer>
  );
};
