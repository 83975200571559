import { useIntl } from '@leagueplatform/locales';
import { useGetAgreementContent } from 'hooks/use-get-agreement-content.hook';
import { StackLayout, Box } from '@leagueplatform/genesis-core';
import { AGREEMENT_CONTENT_TYPES } from 'common/constants';
import type { AgreementContentTypes } from 'common/types';
import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';
import { FullPageError } from 'components/error/full-page-error.component';
import { ErrorTypes } from 'hooks/use-error-message.hook';
import LOGO from 'assets/care-advisor-logo.svg';
import { AgreementContent } from 'components/agreement-content/agreement-content.component';

const topBarHeight = 56;

export const AgreementContentPage = ({
  contentType,
}: {
  contentType: AgreementContentTypes;
}) => {
  const {
    data: contentData,
    isLoading,
    isError,
  } = useGetAgreementContent(contentType);
  const { formatMessage } = useIntl();

  if (isLoading) return <FullPageLoadingSpinner />;

  if (isError)
    return <FullPageError errorType={ErrorTypes.API_CONNECTION_ERROR} />;

  return (
    <Box css={{ padding: '$two', paddingTop: topBarHeight + 90 }}>
      <StackLayout
        verticalAlignment="center"
        css={{
          height: topBarHeight,
          width: '100%',
          position: 'fixed',
          top: 0,
          right: 0,
          zIndex: '$sticky',
          backgroundColor: '$surfaceBackgroundPrimary',
          boxShadow: '$dropdown',
          paddingLeft: '55px',
          '@mobile': {
            paddingLeft: '$oneAndHalf',
            paddingRight: '$oneAndHalf',
          },
        }}
      >
        <a href="/">
          <img
            src={LOGO}
            alt={formatMessage({ id: 'CARE_ADVISOR_CONNECT' })}
            height="21"
          />
        </a>
      </StackLayout>
      <AgreementContent
        htmlString={contentData?.content}
        title={contentData?.title}
        scrollMarginTop="$five"
      />
    </Box>
  );
};

export const TermsOfUsePage = () => (
  <AgreementContentPage contentType={AGREEMENT_CONTENT_TYPES.TERMS_OF_USE} />
);

export const PrivacyPolicyPage = () => (
  <AgreementContentPage contentType={AGREEMENT_CONTENT_TYPES.PRIVACY_POLICY} />
);
