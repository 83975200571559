import { useHistory } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import {
  StackLayout,
  HeadingText,
  UtilityText,
} from '@leagueplatform/genesis-core';
import {
  useGetUserProfile,
  useDocumentTitle,
} from '@leagueplatform/web-common';
import {
  MainPageContainer,
  BackButtonArrow,
} from '@leagueplatform/web-common-components';
import privacyImage from 'assets/privacy.svg';
import { ConsentContent } from 'components/onboarding/consent/consent-content.component';
import { ginaConsentContent } from 'components/onboarding/consent/data';
import { useFeatureFlags } from 'hooks/use-feature-flags.hook';
import { FEATURE_FLAGS } from 'common/firebase';
import { GinaAgreementContentPage } from 'pages/agreement-content/gina-agreement-content.page';

export const HealthAuthorizationPage = () => {
  const { formatMessage, formatDate } = useIntl();
  const history = useHistory();
  useDocumentTitle(formatMessage({ id: 'GINA_CONSENT_VIEW' }));
  const { data: userProfileData } = useGetUserProfile();
  const { featureFlags } = useFeatureFlags([FEATURE_FLAGS.CONSENT_MANAGEMENT]);

  const dateAccepted = userProfileData?.userProfile?.ginaConsent?.dateAccepted;

  // Append "Date Signed" section
  const ginaConsentData =
    typeof dateAccepted === 'string'
      ? ginaConsentContent.concat([
          {
            id: (ginaConsentContent.length + 1).toString(),
            type: 'component',
            content: (
              <StackLayout
                orientation="horizontal"
                spacing="$quarter"
                horizontalAlignment="spaceBetween"
                css={{
                  maxWidth: '768px',
                  margin: '$half',
                  marginBlockStart: '$one',
                }}
              >
                <HeadingText level="4" size="xs">
                  {`${formatMessage({ id: 'DATE_SIGNED' })}:`}
                </HeadingText>
                <UtilityText size="sm">
                  {formatDate(new Date(dateAccepted), {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </UtilityText>
              </StackLayout>
            ),
          },
        ])
      : ginaConsentContent;

  if (featureFlags.rel_as_consent_management)
    return (
      <GinaAgreementContentPage
        includeBackButton
        footer={
          dateAccepted ? (
            <StackLayout
              orientation="horizontal"
              spacing="$quarter"
              horizontalAlignment="spaceBetween"
              css={{
                maxWidth: '768px',
                margin: '$half',
                marginBlockStart: '$one',
              }}
            >
              <HeadingText level="4" size="xs">
                {`${formatMessage({ id: 'DATE_SIGNED' })}:`}
              </HeadingText>
              <UtilityText size="sm">
                {formatDate(new Date(dateAccepted), {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </UtilityText>
            </StackLayout>
          ) : undefined
        }
      />
    );

  // TODO remove when rel_as_consent_management feature flag is deprecated.
  return (
    <MainPageContainer>
      <BackButtonArrow
        display="block"
        fontSize="heading3"
        color="onSurface.text.primary"
        justifyContent="center"
        width="fit-content"
        onClick={() => history.goBack()}
      />
      <ConsentContent
        subTitle={formatMessage({ id: 'GINA_CONSENT_SUB_TITLE' })}
        content={ginaConsentData}
        image={{
          src: privacyImage,
          alt: '',
          width: '115px',
        }}
      />
    </MainPageContainer>
  );
};
