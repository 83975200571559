import { useEffect, useState } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import {
  trackAnalyticsEvent,
  EVENT_NAME,
  PRODUCT_AREA,
  SCREEN_NAMES,
} from '@leagueplatform/analytics';
import { COMMUNICATION_OPTIONS } from 'common/constants';
import { useGetPartnerUserCommunicationInfo } from 'hooks/use-get-partner-user-communication-info.hook';
import {
  usePersonalInformation,
  useUpdateUserProfile,
} from './use-personal-information.hook';

export const useCommunicationPreferences = () => {
  const { formatMessage } = useIntl();
  const updateUserProfile = useUpdateUserProfile();
  const { data: personalInformation, isLoading: personalInformationIsLoading } =
    usePersonalInformation();
  const {
    data: communicationInfoData,
    isLoading: communicationInfoIsLoading,
    isRefetching: communicationInfoIsRefetching,
  } = useGetPartnerUserCommunicationInfo({
    refetchOnWindowFocus: true,
  });

  const isLoading = personalInformationIsLoading || communicationInfoIsLoading;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [disableSms, setDisableSms] = useState<undefined | boolean>();
  const [allowEmailNotification, setAllowEmailNotification] = useState<
    undefined | boolean
  >();
  const [isWaiting, setIsWaiting] = useState('');

  const isSmsNotificationDisabled =
    personalInformation?.userProfile?.notificationPreferences?.disableSms;
  const isEmailNotificationEnabled =
    !!personalInformation?.userProfile?.optedIntoMarketingCommunications;

  useEffect(() => {
    if (
      isLoading === false &&
      (disableSms === undefined || allowEmailNotification === undefined)
    ) {
      setDisableSms(isSmsNotificationDisabled);
      setAllowEmailNotification(isEmailNotificationEnabled);
    }
  }, [
    allowEmailNotification,
    disableSms,
    personalInformation,
    isEmailNotificationEnabled,
    isLoading,
    isSmsNotificationDisabled,
  ]);

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const setSmsNotification = async () => {
    // If we've already done a click and the response isn't back - do nothing.
    if (isWaiting) return;

    setIsWaiting(COMMUNICATION_OPTIONS.SMS);
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.SETTINGS,
      screen_name: SCREEN_NAMES.COMMUNICATION_PREFERENCES,
      detail: 'text preferences selected',
      preferences_status: disableSms ? 'unchecked' : 'checked',
    });

    // if the user doesn't have a phone number and they are trying to opt into SMS notifications,
    // stop them by opening the modal, but if they are trying to opt OUT of SMS, allow it
    if (!personalInformation?.phoneNumber && disableSms) {
      toggleModal();
      setIsWaiting('');
      return;
    }

    const updatedDisableSmsValue = !disableSms;

    try {
      setDisableSms(updatedDisableSmsValue);
      await updateUserProfile({
        notificationPreferences: {
          disableSms: updatedDisableSmsValue,
        },
        optedIntoMarketingCommunications: isEmailNotificationEnabled,
      });
    } catch (err) {
      setDisableSms(!updatedDisableSmsValue);

      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: formatMessage({ id: 'TEXT_NOTIFICATION_PREFERENCE_ERR' }),
      });
    }
    setIsWaiting('');
  };

  const setEmailNotification = async () => {
    if (isWaiting) return;

    setIsWaiting(COMMUNICATION_OPTIONS.EMAIL);
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.SETTINGS,
      screen_name: SCREEN_NAMES.COMMUNICATION_PREFERENCES,
      detail: 'email preferences selected',
      email_status: allowEmailNotification ? 'off' : 'on',
    });

    const optedIntoMarketingCommunications = !allowEmailNotification;

    try {
      setAllowEmailNotification((prev) => !prev);
      await updateUserProfile({
        notificationPreferences: {
          disableSms,
        },
        optedIntoMarketingCommunications,
      });
    } catch (err) {
      setAllowEmailNotification((prev) => !prev);
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: formatMessage({ id: 'EMAIL_NOTIFICATION_PREFERENCE_ERR' }),
      });
    }
    setIsWaiting('');
  };

  return [
    {
      disableSms,
      allowEmailNotification,
      isLoading,
      isModalOpen,
      isWaiting,
      email: communicationInfoData?.email,
      showEmail:
        !!allowEmailNotification && !(isLoading || isWaiting === 'email'),
      isRefetching: communicationInfoIsRefetching,
    },
    { setSmsNotification, setEmailNotification, toggleModal },
  ];
};
