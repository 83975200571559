import type { UserProfileOptions } from '@leagueplatform/web-common';
import snakecaseKeys from 'snakecase-keys';
import {
  GET_USER_PROFILE_MESSAGE_TYPE,
  setUserProfile,
  UserProfile,
} from '@leagueplatform/user-profile-api';
import { TEXT, MULTISELECT } from '@leagueplatform/web-common-components';
import { useQueryClient, useMutation } from 'react-query';
import { useGetUserProfile } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';
import { usePreferredFirstName } from 'hooks/use-preferred-first-name.hook';

export const usePersonalInformation = (options?: UserProfileOptions) => {
  const { data, isLoading, error } = useGetUserProfile(options);
  const {
    data: firstNameData,
    isLoading: firstNameIsLoading,
    error: firstNameError,
  } = usePreferredFirstName();

  const fullName = `${firstNameData?.preferredFirstName?.toUpperCase()} ${
    data?.userProfile.lastName ?? ''
  }`;
  const userInitials = `${firstNameData?.preferredFirstName?.[0] ?? ''}${
    data?.userProfile?.lastName?.[0] ?? ''
  }`;

  const initialValues = {
    preferredFirstName: data?.userProfile?.preferredFirstName,
    pronouns: data?.userProfile?.pronouns,
    genderIdentity: data?.userProfile?.genderIdentity,
  };

  return {
    data: { ...data, fullName, userInitials, initialValues },
    isLoading: isLoading || firstNameIsLoading,
    error: error ?? firstNameError,
  };
};

export const useProfileFormFields = () => {
  const { formatMessage } = useIntl();

  return {
    preferredFirstName: {
      name: 'preferredFirstName',
      inputType: TEXT,
      label: 'WHAT_SHOULD_WE_CALL_YOU_SENTENCE_CASE',
    },
    genderIdentity: {
      name: 'genderIdentity',
      inputType: MULTISELECT,
      label: 'GENDER_IDENTITY_OPTIONAL',
      tooltip: formatMessage({ id: 'GENDER_IDENTITY_TOOLTIP' }),
      placeholder: formatMessage({
        id: 'GENDER_IDENTITY_SELECT_PLACEHOLDER',
      }),
    },
    pronouns: {
      name: 'pronouns',
      inputType: MULTISELECT,
      label: 'PRONOUNS_OPTIONAL',
      tooltip: formatMessage({ id: 'PRONOUNS_TOOLTIP' }),
      placeholder: formatMessage({
        id: 'PRONOUNS_SELECT_PLACEHOLDER',
      }),
    },
    sex: {
      name: 'sex',
      inputType: MULTISELECT,
      label: 'SEX_OPTIONAL',
      tooltip: formatMessage({ id: 'SEX_TOOLTIP' }),
      placeholder: formatMessage({
        id: 'SEX_SELECT_PLACEHOLDER',
      }),
    },
  };
};

/**
 * @name useUpdateUserProfile
 * @desc React hook to update the user profile
 * @returns Function that updates user profile
 */
export const useUpdateUserProfile = () => {
  const queryClient = useQueryClient();
  const updateUserProfileMutation = useMutation(
    (userProfile: Partial<UserProfile>) => setUserProfile(userProfile),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([GET_USER_PROFILE_MESSAGE_TYPE]),
    },
  );

  return async (userProfile: any) => {
    const serializedBody = snakecaseKeys(userProfile);
    await updateUserProfileMutation.mutateAsync(serializedBody);
  };
};
