import { useEffect } from 'react';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';

export const AuthSignIn = () => {
  useEffect(() => {
    StandaloneAuth.client.loginWithRedirect();
  }, []);

  return <FullPageLoadingSpinner />;
};
