import { FullScreenContainer } from 'components/full-screen-container/full-screen-container.component';
import {
  Box,
  Button,
  StackLayout,
  HeadingText,
  ParagraphText,
  Modal,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import completeHighFive from 'assets/complete-high-five.svg';

export const ReacceptGINAAgreementConfirmation = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const { formatMessage } = useIntl();

  return (
    <FullScreenContainer>
      <Modal.Root open onOpenChange={onClose}>
        <Modal.Content>
          <StackLayout
            horizontalAlignment="center"
            css={{ textAlign: 'center' }}
            spacing="$oneAndHalf"
          >
            <img
              src={completeHighFive}
              alt=""
              style={{ width: 138, height: 'auto', position: 'relative' }}
            />
            <Box css={{ marginBottom: '$oneAndHalf' }}>
              <HeadingText level="1" size="xl" css={{ marginBottom: '$half' }}>
                {formatMessage({ id: 'ALL_DONE' })}
              </HeadingText>
              <ParagraphText emphasis="subtle" size="sm">
                {formatMessage({ id: 'GINA_REACCEPT_CONFIRMATION' })}
              </ParagraphText>
            </Box>

            <Button width="fillContainer" onClick={onClose}>
              {formatMessage({ id: 'CLOSE' })}
            </Button>
          </StackLayout>
        </Modal.Content>
      </Modal.Root>
    </FullScreenContainer>
  );
};
