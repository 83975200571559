import { initializeApp } from 'firebase/app';
import type { RemoteConfig } from 'firebase/remote-config';
import type { FeatureFlag } from 'common/types';
import { getRemoteConfig } from 'firebase/remote-config';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);

// Firebase Remote Config
enum FEATURE_FLAGS {
  VIEW_GINA = 'rel_as_view_gina',
  LIVE_CHAT = 'rel_care_liveChat',
  CONSENT_MANAGEMENT = 'rel_as_consent_management',
  MAINTENANCE_PAGE = 'bus_as_maintenancePage',
  EPMP_PREFERENCE = 'rel_as_epmpPreference', // TODO [OPTM-4370] remove rel_as_epmpPreference feature flag
}

// In app default values for feature flags - in case of network errors
const REMOTE_CONFIG_DEFAULT_VALUES: Record<FEATURE_FLAGS, FeatureFlag> = {
  [FEATURE_FLAGS.VIEW_GINA]: false,
  [FEATURE_FLAGS.LIVE_CHAT]: false,
  [FEATURE_FLAGS.CONSENT_MANAGEMENT]: false,
  [FEATURE_FLAGS.MAINTENANCE_PAGE]:
    '{ "enabled": false, "message_end_date": "" }',
  [FEATURE_FLAGS.EPMP_PREFERENCE]: false,
};

const remoteConfig: RemoteConfig = getRemoteConfig(firebaseApp);

remoteConfig.defaultConfig = REMOTE_CONFIG_DEFAULT_VALUES;

// Override the default 12 hours to 1 hour for production (to align with mobile) and 0 for staging (for easier testing).
// We want to use Remote Config feature flags more like we use BE - immediate result after flipping the flag, which is currently impossible.
// Real-time Remote config not available for web (yet?): https://firebase.google.com/docs/remote-config/real-time
remoteConfig.settings.minimumFetchIntervalMillis =
  import.meta.env.VITE_ENV === 'staging' ? 0 : 3600 * 1000; // 1 hour

export { remoteConfig, REMOTE_CONFIG_DEFAULT_VALUES, FEATURE_FLAGS };
