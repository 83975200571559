import { useState } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { useGetCallbackStatus } from 'hooks/use-get-callback-status.hook';
import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';
import { CallbackRequestForm } from 'components/callback-request/callback-request-form.component';
import { CallbackRequestStatus } from 'components/callback-request/callback-request-status.component';
import { CallbackStatusError } from 'components/callback-request/callback-request-error.component';

export const CallbackRequestPage = () => {
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'CALLBACK_REQUEST_PAGE_TITLE' }));

  const [isSuccess, setIsSuccess] = useState(false);
  const { isQueued, isCompleted, isError } = useGetCallbackStatus();

  if (isQueued) return <CallbackRequestStatus queued />;

  if (isSuccess) return <CallbackRequestStatus />;

  if (isError) return <CallbackStatusError />;

  if (isCompleted) return <CallbackRequestForm setSuccess={setIsSuccess} />;

  return <FullPageLoadingSpinner />;
};
