import { isAbsoluteUrl } from '@leagueplatform/web-common';
import { captureError } from '@leagueplatform/observability';
import { LINK_TYPES, SENTRY_CONSTANTS } from 'common/constants';
import { handleDropoffSSO } from 'utils/handle-dropoff-sso.util';

export const handleLink = async (urlString: string) => {
  if (!isAbsoluteUrl(urlString)) {
    captureError(new Error('Target URL is not an absolute url'), {
      errorName: 'SSO External Link Error - Target URL is not an absolute url',
      context: {
        Context: {
          ssoUrl: urlString,
        },
      },
      ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
      tags: {
        sso: urlString,
      },
    });

    return;
  }

  const url = new URL(urlString);
  const linkType = url?.searchParams.get('linkType');

  switch (linkType?.toLowerCase()) {
    case LINK_TYPES.DROPOFF:
      await handleDropoffSSO(url);
      break;
    default:
      window.open(urlString, '_blank', 'noreferrer');
  }
};
