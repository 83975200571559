import type { UseAuthOptions } from '@leagueplatform/auth-utils';
import type { AuthError } from 'common/types';
import { useState, useEffect } from 'react';
import { useAuth } from '@leagueplatform/auth-utils';
import { useCaptureAuthError } from 'hooks/use-capture-auth-error';
import { getUserEligibility } from 'api/get-user-eligibility';
import { AUTH_VALIDATION_ERRORS } from 'common/constants';

type AppAuthorizationOptions = {
  checkUserEligibility: boolean;
  useAuthOptions: UseAuthOptions;
};

export const useAppAuthorization = (
  { checkUserEligibility, useAuthOptions }: AppAuthorizationOptions = {
    checkUserEligibility: false,
    useAuthOptions: {},
  },
) => {
  const {
    isAuthenticated,
    error: authError,
    appState,
  } = useAuth(useAuthOptions);

  const [isEligible, setIsEligible] = useState<boolean | undefined>();
  const [error, setError] = useState<AuthError>(null);
  useCaptureAuthError({ isAuthenticated, error });

  // Once authenticated if desired get the user eligibility
  useEffect(() => {
    if (checkUserEligibility && isAuthenticated && isEligible === undefined) {
      getUserEligibility(
        (eligibility: boolean) => setIsEligible(eligibility),
        isAuthenticated,
      );
    }
  }, [isAuthenticated, isEligible, checkUserEligibility]);

  // Handle changes to error states
  useEffect(() => {
    if (authError) {
      setError(authError);
    } else if (checkUserEligibility && isEligible === false) {
      setError({
        description: AUTH_VALIDATION_ERRORS.INELIGIBLE_USER,
      });
    }
  }, [isEligible, authError, checkUserEligibility]);

  return {
    error,
    isAuthenticated,
    appState,
    isEligible,
    isLoading:
      isAuthenticated === undefined ||
      (checkUserEligibility &&
        isAuthenticated === true &&
        isEligible === undefined),
  };
};
