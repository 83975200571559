import { type GDSColor, Box } from '@leagueplatform/genesis-core';
import React from 'react';

export const FullScreenContainer = ({
  backgroundColor = '$surfaceBackgroundPrimary',
  children,
}: {
  backgroundColor?: GDSColor;
  children: React.ReactNode;
}) => (
  <Box
    role="dialog"
    css={{
      width: '100vw',
      height: '100vh',
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: '100',
      overflow: 'auto',
      backgroundColor,
    }}
  >
    {children}
  </Box>
);
