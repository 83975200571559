import { useState, useEffect } from 'react';
import {
  Button,
  StackLayout,
  HeadingText,
  ParagraphText,
  useMediaQuery,
  queryHelpers,
  ImageMessage,
  Modal,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import {
  trackAnalyticsEvent,
  EVENT_NAME,
  PRODUCT_AREA,
  SCREEN_NAMES,
} from '@leagueplatform/analytics';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import { useGetUserProfile } from '@leagueplatform/web-common';
import { BaseModalContainer } from 'components/modals/base-modal.component';
import exitWarning from 'assets/exit-warning.svg';
import { deleteUserAccount } from 'api/delete-user-account';

export interface Props {
  onSubmit: () => void;
  isOpen?: boolean;
}
export const DeleteAccountModal = ({ onSubmit, isOpen }: Props) => {
  const { data } = useGetUserProfile();
  const userId = data?.userId;
  const [isDeleting, setIsDeleting] = useState(false);
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery(queryHelpers.only('mobile'));

  useEffect(() => {
    if (isOpen) {
      trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
        product_area: PRODUCT_AREA.SETTINGS,
        screen_name: SCREEN_NAMES.DELETE_ACCOUNT,
      });
    }
  }, [isOpen]);

  return (
    <BaseModalContainer
      title={formatMessage({ id: 'DELETE_ACCOUNT' })}
      footer={
        <StackLayout
          horizontalAlignment="end"
          orientation={{
            '@initial': 'horizontal',
            '@mobile': 'vertical',
          }}
          spacing="$one"
          css={{ flexGrow: 0 }}
        >
          <Modal.Close>
            <Button
              type="submit"
              destructive
              size={{
                '@initial': 'medium',
                '@mobile': 'large',
              }}
              width={{
                '@initial': 'hugContents',
                '@mobile': 'fillContainer',
              }}
              loading={isDeleting}
              onClick={async () => {
                trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
                  product_area: PRODUCT_AREA.SETTINGS,
                  screen_name: SCREEN_NAMES.DELETE_ACCOUNT,
                  detail: 'confirm deletion',
                });
                if (userId) {
                  try {
                    setIsDeleting(true);
                    await deleteUserAccount(userId);
                    setIsDeleting(false);
                    onSubmit();
                  } catch (e) {
                    setIsDeleting(false);
                    toastActions.add({
                      type: TOAST_STATUS.ERROR,
                      textId: 'DELETE_ACCOUNT_FAILED',
                    });
                  }
                }
              }}
            >
              {formatMessage({ id: 'YES_DELETE_ACCOUNT' })}
            </Button>
          </Modal.Close>
          <Modal.Close>
            <Button
              priority="tertiary"
              quiet={isMobile}
              width={{
                '@initial': 'hugContents',
                '@mobile': 'fillContainer',
              }}
              size={{
                '@initial': 'medium',
                '@mobile': 'large',
              }}
              css={{
                order: isMobile ? 0 : '-1',
              }}
            >
              {formatMessage({ id: 'NEVER_MIND' })}
            </Button>
          </Modal.Close>
        </StackLayout>
      }
    >
      <ImageMessage
        image={exitWarning}
        imageWidth={146}
        css={{
          flexGrow: 1,
          padding: '$two $half $none',
          textAlign: 'left',
          '@mobile': { textAlign: 'center' },
        }}
      >
        <HeadingText
          level="2"
          size={{
            '@initial': 'lg',
            '@mobile': 'xl',
          }}
          css={{ paddingBlock: '$one' }}
        >
          {formatMessage({ id: 'DELETE_ACCOUNT_TITLE' })}
        </HeadingText>
        <ParagraphText>
          {formatMessage({ id: 'DELETE_ACCOUNT_BODY_1' })}
        </ParagraphText>
        <ParagraphText css={{ marginBlock: '$one' }}>
          {formatMessage({ id: 'DELETE_ACCOUNT_BODY_2' })}
        </ParagraphText>
      </ImageMessage>
    </BaseModalContainer>
  );
};
