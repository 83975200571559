import { StackLayout } from '@leagueplatform/genesis-core';
import { FullScreenContainer } from 'components/full-screen-container/full-screen-container.component';
import { LoadingSpinner } from './loading-spinner.component';

export const FullPageLoadingSpinner = () => (
  <FullScreenContainer>
    <StackLayout
      horizontalAlignment="center"
      verticalAlignment="center"
      css={{ position: 'relative', top: '-$one' }}
    >
      <LoadingSpinner />
    </StackLayout>
  </FullScreenContainer>
);
