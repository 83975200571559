import { useIntl } from '@leagueplatform/locales';

export enum ErrorTypes {
  API_ERROR = 'api_error',
  API_CONNECTION_ERROR = 'api_connection_error',
  NO_CONNECTION = 'no_connection',
}

export const useErrorMessage = (errorType: ErrorTypes) => {
  const { formatMessage } = useIntl();

  type ErrorBody = {
    headingId: string;
    captionId: string;
  };

  const ErrorMessages: Record<ErrorTypes, ErrorBody> = {
    [ErrorTypes.NO_CONNECTION]: {
      headingId: 'ERROR_HEADING_NO_CONNECTION',
      captionId: 'ERROR_CAPTION_NO_CONNECTION',
    },
    [ErrorTypes.API_ERROR]: {
      headingId: 'ERROR_HEADING_GENERIC',
      captionId: 'ERROR_CAPTION_BACKEND',
    },
    [ErrorTypes.API_CONNECTION_ERROR]: {
      headingId: 'ERROR_HEADING_GENERIC',
      captionId: 'ERROR_CAPTION_API_CONNECTION',
    },
  };

  return {
    errorHeading: formatMessage({ id: ErrorMessages[errorType].headingId }),
    errorCaption: formatMessage({ id: ErrorMessages[errorType].captionId }),
  };
};
