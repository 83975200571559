import { useIntl } from '@leagueplatform/locales';
import { useQueryClient } from 'react-query';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import { useSetAgreementStatus } from 'hooks/use-set-agreement-status.hook';
import { ConsentFooter } from 'components/onboarding/consent/consent-footer.component';
import {
  AGREEMENT_STATUS_TYPES,
  MASONRY,
  MESSAGE_TYPES,
} from 'common/constants';
import { GinaAgreementContentPage } from 'pages/agreement-content/gina-agreement-content.page';

const HomePageMasonryQuery = [
  MESSAGE_TYPES.GET_WIDGET_CONTAINER,
  MASONRY.HOME_PAGE_APP_ID,
  MASONRY.API_VERSION,
];

export const ReacceptGINAAgreementContent = ({
  onAcceptSuccess,
  handleGoBack,
}: {
  onAcceptSuccess: () => void;
  handleGoBack?: () => void;
}) => {
  const { formatMessage } = useIntl();

  const { mutate: mutateAgreementStatus, isLoading: mutationIsLoading } =
    useSetAgreementStatus();
  const queryClient = useQueryClient();

  const handleOnAccept = () => {
    mutateAgreementStatus(
      {
        agreementType: AGREEMENT_STATUS_TYPES.GINA,
        isAccepted: true,
      },
      {
        onSuccess: () => {
          // Clear the query cache and proactively start refetching the query for the home page masonry component.
          queryClient.resetQueries(HomePageMasonryQuery);
          queryClient.refetchQueries(HomePageMasonryQuery);

          onAcceptSuccess();
        },
        onError: () => {
          toastActions.add({
            type: TOAST_STATUS.ERROR,
            textId: formatMessage({ id: 'GINA_CONSENT_ERROR' }),
          });
        },
      },
    );
  };

  return (
    <GinaAgreementContentPage
      fullScreen
      footer={
        <ConsentFooter
          isLoading={mutationIsLoading}
          acceptText={formatMessage({ id: 'AGREE_AND_CONTINUE' })}
          onAccept={handleOnAccept}
          backButton={
            handleGoBack
              ? {
                  text: formatMessage({ id: 'BACK' }),
                  onBack: handleGoBack,
                }
              : undefined
          }
        />
      }
    />
  );
};
