import {
  Box,
  VisuallyHidden,
  styled,
  keyframes,
  useTheme,
} from '@leagueplatform/genesis-core';
import spinner from 'assets/loading.svg';

const RotateAnimation = keyframes({
  to: {
    transform: 'rotate(360deg)',
  },
});

const SpinnerPositioner = styled(Box, {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

const CircleOutline = styled(Box, {
  borderWidth: '6px',
  borderStyle: 'solid',
  borderRadius: '100px',
  height: '48px',
  width: '48px',
});

const AnimatedSpinner = styled(Box, {
  height: '48px',
  width: '48px',
  backgroundImage: `url(${spinner})`,
  animation: `${RotateAnimation} 1s linear infinite forwards`,
});

export const LoadingSpinner = () => {
  const theme = useTheme();
  return (
    <Box
      css={{
        position: 'relative',
      }}
    >
      <CircleOutline
        aria-hidden="true"
        style={{ borderColor: theme.colors.surfaceBackgroundSecondary }}
      />
      <SpinnerPositioner aria-hidden="true">
        <AnimatedSpinner />
      </SpinnerPositioner>
      <VisuallyHidden>Loading.</VisuallyHidden>
    </Box>
  );
};

LoadingSpinner.displayName = 'LoadingSpinner';
