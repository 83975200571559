import { useEffect } from 'react';
import { useHistory } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import {
  trackAnalyticsEvent,
  EVENT_NAME,
  PRODUCT_AREA,
  SUB_PRODUCT_AREA,
  SCREEN_NAMES,
} from '@leagueplatform/analytics';
import {
  HeadingText,
  ParagraphText,
  StackLayout,
  ImageMessage,
} from '@leagueplatform/genesis-core';
import IMAGE from 'assets/callback-success.png';
import { CallbackRequestLayout } from './callback-request-layout.component';

const queuedStringKeys = {
  heading: 'CALLBACK_QUEUED_HEADING',
  bodyOne: 'CALLBACK_QUEUED_BODY_1',
  bodyTwo: 'CALLBACK_QUEUED_SUCCESS_BODY_2',
};

const successStringKeys = {
  heading: 'CALLBACK_SUCCESS_HEADING',
  bodyOne: 'CALLBACK_SUCCESS_BODY_1',
  bodyTwo: 'CALLBACK_QUEUED_SUCCESS_BODY_2',
};

export const CallbackRequestStatus = ({ queued }: { queued?: boolean }) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { heading, bodyOne, bodyTwo } = queued
    ? queuedStringKeys
    : successStringKeys;

  useEffect(() => {
    trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
      product_area: PRODUCT_AREA.CARE,
      sub_product_area: SUB_PRODUCT_AREA.CALLBACK,
      screen_name: SCREEN_NAMES.CALLBACK_REQUEST_CONFIRMATION,
    });
  }, []);

  const handleClick = () => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.CARE,
      sub_product_area: SUB_PRODUCT_AREA.CALLBACK,
      screen_name: SCREEN_NAMES.CALLBACK_REQUEST_CONFIRMATION,
      detail: 'got it',
    });

    history.goBack();
  };

  return (
    <CallbackRequestLayout
      onClick={handleClick}
      buttonText={formatMessage({ id: 'GOT_IT' })}
    >
      <ImageMessage
        image={IMAGE}
        css={{ img: { borderRadius: '$large' }, margin: '$two' }}
      >
        <StackLayout spacing="$one" horizontalAlignment="stretch">
          <HeadingText level="1" size="xxl">
            {formatMessage({ id: heading })}
          </HeadingText>
          <ParagraphText>{formatMessage({ id: bodyOne })}</ParagraphText>
          <ParagraphText>{formatMessage({ id: bodyTwo })}</ParagraphText>
        </StackLayout>
      </ImageMessage>
    </CallbackRequestLayout>
  );
};
