import { GDSSpacing } from '@leagueplatform/genesis-core';

// These tokens are used to create padding within pages and components
// These are generic tokens consistent across all PBLs

const one = 16;

export const spacing: GDSSpacing = {
  none: 0,
  quarter: one * 0.25, // 4
  half: one * 0.5, // 8
  threeQuarters: one * 0.75, // 12
  one, // 16
  oneAndHalf: one * 1.5, // 24
  two: one * 2, // 32
  twoAndHalf: one * 2.5, // 40
  three: one * 3, // 48
  four: one * 4, // 64
  five: one * 5, // 80
  six: one * 6, // 96
};
