import type { MouseEvent } from 'react';
import { useState, useRef } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import {
  StackLayout,
  TextAction,
  InputLabel,
  TextInput,
  InputHint,
  Button,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import { usePersonalInformation } from 'hooks/use-personal-information.hook';
import { useUpdateUserProfile } from 'hooks/use-update-user-profile.hook';

type EditDisplayNameProps = {
  editButtonOnClickCallback?: (e?: MouseEvent<HTMLButtonElement>) => void;
  saveButtonOnClickCallback?: (e?: MouseEvent<HTMLButtonElement>) => void;
  cancelButtonOnClickCallback?: (e?: MouseEvent<HTMLButtonElement>) => void;
};

export const EditDisplayName = ({
  editButtonOnClickCallback = () => {},
  saveButtonOnClickCallback = () => {},
  cancelButtonOnClickCallback = () => {},
}: EditDisplayNameProps) => {
  const { formatMessage } = useIntl();

  const [isFormVisible, setIsFormVisible] = useState(false);

  const { data } = usePersonalInformation();
  const { firstName, lastName, preferredFirstName } = data?.userProfile || {};
  const legalName = `${firstName} ${lastName}`;
  const initialDisplayName = preferredFirstName || legalName;
  const [displayName, setDisplayName] = useState(initialDisplayName);

  const editButtonRef = useRef<HTMLButtonElement>(null);

  // Need async to wait for the edit button to render before passing a ref.
  const closeFormAndFocusEditButton = async () => {
    await setIsFormVisible(false);
    editButtonRef.current?.focus();
  };

  const onSuccess = () => {
    closeFormAndFocusEditButton();
    toastActions.add({
      type: TOAST_STATUS.SUCCESS,
      textId: 'DISPLAY_NAME_UPDATED',
    });
  };

  const onError = () => {
    setDisplayName(initialDisplayName);
    toastActions.add({
      type: TOAST_STATUS.ERROR,
      textId: 'DISPLAY_NAME_UPDATE_FAILED',
    });
  };

  const { updateUserProfile, isLoading } = useUpdateUserProfile({
    onSuccess,
    onError,
  });

  const handleSubmit = async () => {
    await updateUserProfile({ preferredFirstName: displayName });
    // If the user deleted the preferred name,
    // first the empty string is sent to the backend,
    // but in the local state for UI we show the legal name.
    if (displayName.length === 0) setDisplayName(legalName);
    saveButtonOnClickCallback();
  };

  const handleCancel = () => {
    setDisplayName(initialDisplayName);
    closeFormAndFocusEditButton();
    cancelButtonOnClickCallback();
  };

  const handleEditClick = (e: MouseEvent<HTMLButtonElement>) => {
    setIsFormVisible(true);
    editButtonOnClickCallback(e);
  };

  return (
    <StackLayout
      horizontalAlignment={isFormVisible ? 'stretch' : 'center'}
      css={{
        margin: '$half auto',
      }}
    >
      {isFormVisible ? (
        <>
          <InputLabel
            htmlFor="display-name"
            required
            css={{ marginBottom: '$half' }}
          >
            {formatMessage({ id: 'DISPLAY_NAME_LABEL' })}
          </InputLabel>
          <TextInput
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            css={{ marginBottom: '$half' }}
            id="display-name"
            name="display-name"
            aria-describedby="display-name-hint"
            autoFocus
          />
          <InputHint id="display-name-hint">
            {formatMessage({ id: 'DISPLAY_NAME_HINT' })}
          </InputHint>
          <StackLayout
            orientation="horizontal"
            horizontalAlignment="spaceBetween"
            verticalAlignment="center"
            css={{ marginTop: '$half' }}
          >
            <TextAction as="button" onClick={() => handleCancel()}>
              {formatMessage({ id: 'CANCEL' })}
            </TextAction>
            <Button onClick={handleSubmit} loading={isLoading}>
              {formatMessage({ id: 'SAVE' })}
            </Button>
          </StackLayout>
        </>
      ) : (
        <>
          <ParagraphText css={{ typography: '$headingThree' }}>
            {displayName}
          </ParagraphText>
          <TextAction ref={editButtonRef} as="button" onClick={handleEditClick}>
            {formatMessage({ id: 'EDIT_DISPLAY_NAME' })}
          </TextAction>
        </>
      )}
    </StackLayout>
  );
};
