import { ReactNode, useEffect, useMemo, Fragment } from 'react';
import { useAnalyticsIdentify } from '@leagueplatform/web-common';
import { useOnboarding } from 'hooks/use-onboarding.hook';
import { useTBKToken } from 'hooks/use-tbk-token/use-tbk-token.hook';
import { loadNPSScript } from 'utils/load-nps-script';
import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';
import { ErrorFallback } from 'components/error/error-boundary.component';
import { GinaConsent } from 'components/onboarding/consent/consent-wrapper.component';
import { AccountSetupForm } from 'components/forms/account-setup/account-setup.form.component';
import { FeatureHighlights } from 'components/onboarding/feature-highlights.component';
import { AgreementStatusCheckContainer } from 'components/agreement-status-check-container/agreement-status-check-container.component';
import { useFeatureFlags } from 'hooks/use-feature-flags.hook';
import { FEATURE_FLAGS } from 'common/firebase';
import { useConfigProperty } from '@leagueplatform/core';

export const PrivateRoutesContainer = ({
  children,
}: {
  children: ReactNode;
}) => {
  const {
    status,
    isLoading,
    isError,
    setStatus,
    handleFeatureHighlightCompleted,
  } = useOnboarding();
  const { featureFlags } = useFeatureFlags([FEATURE_FLAGS.CONSENT_MANAGEMENT]);

  // Send user data to analytics SDK
  const tenantId: string | undefined = useConfigProperty('core.tenantId');
  const identifyOptions = useMemo(
    () => ({
      employer_group_id: '',
      // TODO: configure Segment's dashboards to use the term `app ID` instead of `tenant ID`
      tenant_id: tenantId!,
    }),
    [tenantId],
  );
  useAnalyticsIdentify(identifyOptions);

  // Load Qualtrics NPS script when status is 'completed'
  useEffect(() => {
    if (status === 'completed') {
      loadNPSScript();
    }
  }, [status]);

  // Set TBK auth token in local storage
  useTBKToken();

  if (isLoading) return <FullPageLoadingSpinner />;

  if (isError) {
    return <ErrorFallback />;
  }

  if (status === 'completed') {
    const ConsentManagementFlagWrapper = featureFlags.rel_as_consent_management
      ? AgreementStatusCheckContainer
      : Fragment;

    return (
      <ConsentManagementFlagWrapper>{children}</ConsentManagementFlagWrapper>
    );
  }

  if (status === 'ginaConsent') {
    return <GinaConsent setStatus={setStatus} />;
  }

  if (status === 'accountSetup') {
    return <AccountSetupForm setStatus={setStatus} />;
  }

  if (status === 'featureHighlights') {
    return <FeatureHighlights onComplete={handleFeatureHighlightCompleted} />;
  }

  return null;
};
