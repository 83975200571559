import type { UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';
import { getPartnerUserCommunicationInfo } from 'api/get-partner-user-communication-info';
import { REST_ENDPOINTS } from 'common/constants';
import {
  PartnerUserCommunicationInfoResponse,
  PartnerUserCommunicationInfo,
} from 'common/types';

export const useGetPartnerUserCommunicationInfo = (
  queryOptions?: Omit<
    UseQueryOptions<
      PartnerUserCommunicationInfoResponse,
      unknown,
      PartnerUserCommunicationInfo,
      string[]
    >,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery(
    [REST_ENDPOINTS.PARTNER_USER_COMMUNICATION_INFO],
    async () => getPartnerUserCommunicationInfo(),
    {
      select: (data: PartnerUserCommunicationInfoResponse) =>
        data?.data?.attributes,
      ...queryOptions,
    },
  );
