import { useState, useEffect, FormEvent } from 'react';
import { useMutation } from 'react-query';
import { useIntl } from '@leagueplatform/locales';
import {
  trackAnalyticsEvent,
  EVENT_NAME,
  PRODUCT_AREA,
  SUB_PRODUCT_AREA,
  SCREEN_NAMES,
} from '@leagueplatform/analytics';
import {
  isPhoneNumber,
  stripPhoneNumber,
  formatPhoneNumber,
} from 'utils/phone-number';
import IMAGE from 'assets/callback-request.png';
import { requestCallback } from 'api/request-callback';
import { useGetUserProfile } from '@leagueplatform/web-common';
import {
  HeadingText,
  UtilityText,
  FormField,
  TextInput,
  StatusBanner,
  StackLayout,
  Box,
} from '@leagueplatform/genesis-core';
import { CallbackRequestError } from './callback-request-error.component';
import { CallbackRequestLayout } from './callback-request-layout.component';

const HeaderImage = () => (
  <Box
    css={{
      background: `url(${IMAGE})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: '50% center',
      aspectRatio: ' 480 / 270',
      width: '100%',
      overflow: 'hidden',
    }}
  >
    <Box
      css={{
        width: '100%',
        height: '100%',
        background: 'linear-gradient(to top, rgba(0,0,0,.8) , rgba(0,0,0,0))',
      }}
    />
  </Box>
);

export const CallbackRequestForm = ({
  setSuccess,
}: {
  setSuccess: (value: boolean) => void;
}) => {
  const { formatMessage } = useIntl();
  const [phoneNumberValue, setPhoneNumberValue] = useState('');
  const [validationError, setValidationError] = useState(false);

  const { data: { phoneNumber } = {} } = useGetUserProfile();
  useEffect(() => {
    if (phoneNumber) setPhoneNumberValue(phoneNumber);
  }, [phoneNumber]);

  useEffect(() => {
    trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
      product_area: PRODUCT_AREA.CARE,
      sub_product_area: SUB_PRODUCT_AREA.CALLBACK,
      screen_name: SCREEN_NAMES.CALLBACK_REQUEST_FORM,
    });
  }, []);

  // Submit form with React Query
  const {
    mutate: requestPhoneCall,
    isLoading,
    isError: isServerError,
    reset,
  } = useMutation((number: string) => requestCallback(number), {
    onSuccess: () => setSuccess(true),
  });

  const handleSubmit = async (e: FormEvent) => {
    // Send analytics first
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.CARE,
      sub_product_area: SUB_PRODUCT_AREA.CALLBACK,
      screen_name: SCREEN_NAMES.CALLBACK_REQUEST_FORM,
      detail: 'send request',
    });

    if (e) e.preventDefault();
    // Validate input and prevent submit if error
    if (!isPhoneNumber(phoneNumberValue)) {
      setValidationError(true);
      return;
    }
    // Optum API accepts strictly 10 characters, all digits
    await requestPhoneCall(stripPhoneNumber(phoneNumberValue));
  };

  return (
    <CallbackRequestLayout
      buttonText={formatMessage({ id: 'CALLBACK_REQUEST_SUBMIT_BUTTON' })}
      onClick={handleSubmit}
      isLoading={isLoading}
      buttonType="submit"
    >
      <CallbackRequestError onClose={() => reset()} open={isServerError} />
      <HeaderImage />
      <StackLayout
        spacing="$oneAndHalf"
        css={{
          borderRadius: '$large',
          marginTop: '$minusOne',
          background: '$surfaceBackgroundPrimary',
          padding: '$two',
          height: 'min-content',
        }}
      >
        <HeadingText level="1" size="xl">
          {formatMessage({ id: 'CALLBACK_REQUEST_HEADING' })}
        </HeadingText>
        <UtilityText>
          {formatMessage({ id: 'CALLBACK_REQUEST_BODY_1' })}
        </UtilityText>
        <UtilityText emphasis="emphasized">
          {formatMessage({ id: 'CALLBACK_REQUEST_BODY_2' })}
        </UtilityText>
        <StackLayout
          as="form"
          onSubmit={(e) => handleSubmit(e)}
          horizontalAlignment="stretch"
          spacing="$oneAndHalf"
          css={{ height: 'unset' }}
        >
          <FormField
            label={formatMessage({ id: 'CALLBACK_REQUEST_LABEL' })}
            id="phone"
            inputStatus={validationError ? 'error' : undefined}
            statusMessage={formatMessage({
              id: 'PHONE_NUMBER_VALIDATION_ERROR_MESSAGE',
            })}
          >
            <TextInput
              autoFocus
              type="tel"
              onChange={(e) => {
                setPhoneNumberValue(formatPhoneNumber(e.currentTarget.value));
                setValidationError(false);
              }}
              inputStatus={validationError ? 'error' : undefined}
              value={phoneNumberValue}
            />
          </FormField>
          <StatusBanner
            status="neutral"
            icon="interfaceQuestionCircleFilled"
            title={formatMessage({ id: 'CALLBACK_REQUEST_DISCLAIMER_TITLE' })}
          >
            {formatMessage({ id: 'CALLBACK_REQUEST_DISCLAIMER_BODY' })}
          </StatusBanner>
        </StackLayout>
      </StackLayout>
    </CallbackRequestLayout>
  );
};
