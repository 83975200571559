export const EN_STRING_OVERRIDES = {
  ADVANCE_TO_NEXT: 'Advance to next',
  CONTINUE: 'Continue',
  SAVE: 'Save',
  ERROR_CAPTION_BACKEND:
    'There was a problem loading data.\nPlease try again later.',
  ERROR_CAPTION_NO_CONNECTION:
    'Please check your internet.\nThis page will automatically reload when you reconnect.',
  ERROR_HEADING_GENERIC: 'Something went wrong',
  ERROR_HEADING_NO_CONNECTION: 'No internet connection',
  ERROR_CAPTION_API_CONNECTION:
    'There was a problem connecting to our server.\nPlease try again later.',
  GENDER_IDENTITY_SELECT_PLACEHOLDER: 'Select your gender identity',
  GENDER_IDENTITY_TOOLTIP:
    'We support gender expression and we’d like to make sure we get yours right. Gender identity is the personal sense of your own gender. It may or may not be the same as your biological sex at birth.',
  GET_ASSISTANCE_HEADING: 'Get Assistance Now',
  GREETINGS_SUBHEADING:
    'Your single destination for health, wellness, care, and benefits.',
  PRONOUNS_SELECT_PLACEHOLDER: 'Select your pronouns',
  PRONOUNS_TOOLTIP:
    'Add your pronouns so we know how to refer to you. For example: If Alex’s pronouns are they/them, we could say “Alex recently enrolled in a mental health program, and they’ve noticed a boost in their mental wellbeing.”',
  PROVIDED_BY_WITH_COLON: 'Provided by:',
  RETURN_TO_PREVIOUS: 'Return to previous',
  SKIP_TUTORIAL: 'Skip tutorial',
  SUPPORTS_GENDER_EXPRESSION_SUBHEADING:
    'Optum supports gender expression and would like to know how you identify',
  THIS_INFO_WILL_NOT_BE_SHARED:
    'This information will not be shared with your employer',
  SETUP_PROFILE_PHOTO_TITLE: 'Set up your profile photo',
  SETUP_PROFILE_PHOTO_DESCRIPTION:
    'You can take a new photo or choose one from your photo library.',
  ADD_PHOTO: 'Add photo',
  ENABLE_NOTIFICATIONS: 'Enable notifications',
  ENABLE_NOTIFICATIONS_DESCRIPTION:
    'Never miss our health nudges that help you achieve your health goals.',
  SEX_OPTIONAL: 'Sex (Optional)',
  SEX_SELECT_PLACEHOLDER: 'Select your sex',
  RECEIVE_OCCASIONAL_EMAIL:
    'I’d like to receive occasional emails with reminders and tips to get the most out of Optum Guide.',
  ACCOUNT_SETUP_TITLE: 'Just a couple more steps',
  ACCOUNT_SETUP_DESCRIPTION:
    'Finish setting up your account to start exploring optum.',
  YOUR_JOURNEY: 'Today’s Activities',
  CARE: 'Care',
  ABOUT_OPTUM_GUIDE: 'About Optum Guide',
  PERSONAL_CARE_TEAM: 'Personal care team',
  SHARING_PERMISSIONS: 'Sharing permissions',
  DELETE_ACCOUNT: 'Delete your account',
  DELETE_ACCOUNT_TITLE: 'Are you sure?',
  DELETE_ACCOUNT_BODY_1:
    'As a result of deleting your account, you will no longer be able to access the Optum Guide app. You will still have access to your Health Plan Portal.',
  DELETE_ACCOUNT_BODY_2:
    ' If you want to reactivate your account in the future, call the member services number on the back of your health insurance ID card so our Care Guide Team can assist you. ',
  NEVER_MIND: 'Never mind',
  I_UNDERSTAND: 'I understand',
  YES_DELETE_ACCOUNT: 'Yes, delete this account',
  ACCOUNT_DELETED: 'Account deleted',
  ACCOUNT_DELETED_TITLE: 'Your account is now deleted',
  ACCOUNT_DELETED_BODY:
    'Please note that we are required by law to retain certain information associated with your account. As soon as we are legally able to delete this information, it will be deleted in accordance with our data deletion and retention policy. Until then, it is protected and stored securely and is not used in any way.',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  EMAIL_ADDRESS: 'Email Address',
  DATE_OF_BIRTH: 'Date of Birth',
  PREFERRED_NAME: 'Preferred name (optional)',
  PERSONAL_INFO: 'Personal info',
  UPDATE_PROFILE_PHOTO: 'Change profile photo',
  ACTIVE_PROGRAMS: 'Active courses',
  ACTIVE_PROGRAM: 'Active {length, plural, one {Course} other {Courses}}',
  PROGRAM_LIBRARY: 'Course Library',
  HEALTH_PROGRAMS_SYNC_DEVICE:
    'Sync your {device} for the optimal course experience.',
  HEALTH_PROGRAMS_CONNECTED_PROGRAM: 'Connected health course',
  COMPLETED_PROGRAM_DETAILS: 'Completed Course Details',
  IN_PROGRESS_PROGRAM_DETAILS: 'In Progress Course Details',
  EXPLORE_PROGRAMS: 'Explore Courses',
  ABOUT_THIS_PROGRAM: 'About this course',
  BROWSE_PROGRAMS: 'Browse Courses',
  PROGRAM_DETAILS: 'Course Details',
  ADD_PROGRAM: 'Add Course',
  YOU_HAVENT_STARTED_PROGRAMS: 'Start adding new courses',
  TAP_ADD_PROGRAM_BUTTON:
    'Tap the Add button to browse courses. The ones you choose will show up here.',
  YOUVE_REACHED_PROGRAM_LIMIT: 'You’ve reached your course limit!',
  START_X_MORE_PROGRAMS: 'Start more courses. Keep the momentum.',
  COMPLETE_ONE_OF_ACTIVE_PROGRAMS:
    'Complete one of your active courses to add this to your journey.',
  LOOKS_LIKE_REACHED_PROGRAM_LIMIT:
    'Looks like you’ve reached your course limit!',
  COMPLETE_ACTIVE_PROGRAM_BEFORE_NEW_ONE:
    'Complete an active course before adding a new one. <strong>{ctaText}</strong>',
  REMOVE_PROGRAM: 'Dismiss course?',
  REMOVE_PROGRAM_BUTTON: 'Dismiss',
  ARE_YOU_SURE_REMOVE_PROGRAM:
    'Are you sure you want to dismiss this course from your journey? Any progress in this course will be reset and you will have to start over from the beginning.',
  PROGRAM_REMOVED: 'Course dismissed',
  PROGRAM_ADDED: 'Course added',
  THE_PROGRAM_WILL_APPEAR_IN_JOURNEY:
    'The course has been added to your journey',
  PROGRAM_ADD_ERROR:
    'We were unable to add the course at this time. Please try again or choose another course.',
  PROGRAM_REMOVE_ERROR:
    'We were unable to dismiss the course at this time. Please try again.',
  PAGE_TITLE_HEALTH_PROGRAM_CATEGORY: 'Health Course Category',
  BUTTON_BROWSE_PROGRAMS: 'Browse Courses',
  ACTIVITIES_PILL: 'Activities',
  ACTIVITIES: 'Activities',
  ACTIVITIES_PLURALIZATION:
    '{count, plural, one {Activity} other {Activities}}',
  ABOUT_OPTUM_GUIDE_DESCRIPTION:
    'This software does not perform any of the following: diagnosing, treating, mitigating or preventing a disease, disorder or abnormal physical state (or any of their symptoms), nor does it otherwise meet the definition of Software as a Medical Device (SaMD).',
  ALLOW_PUSH_NOTIFICATION: 'Allow push notifications',
  COMMUNICATION_PREFERENCES_ALLOW_EMAIL:
    'I’d like to receive occasional emails with reminders and tips to get the most out of Optum Guide.',
  COMMUNICATION_PREFERENCES_ALLOW_TEXT:
    "I'd like to receive texts with reminders to get the most out of Optum Guide.",
  NO_ACTIVITIES_COMPLETED_TODAY_HEADING: 'Well done! You’re on a roll!',
  NO_ACTIVITIES_COMPLETED_TODAY_DESCRIPTION:
    'Check here every day to see new activities shared by your Care Guide or lessons from the courses you’re enrolled in.',
  NO_ACTIVITIES_FUTURE_DESCRIPTION:
    'Check here every day to see new activities shared by your Care Guide or lessons from the courses you’re enrolled in.',
  NO_ACTIVITIES_HAS_PROGRAMS_TODAY_DESCRIPTION:
    'Check here every day to see new activities shared by your Care Guide or lessons from the courses you’re enrolled in.',
  DISCOVER_ACTIVITIES: 'Discover new courses',
  DISCOVER_ACTIVITIES_CAPTION: 'Browse courses we think you’ll love',
  HOW_CAN_WE_HELP: 'Helpful actions',
  SEND_MESSAGE: 'Send message',
  MESSAGE: 'Message',
  SELECT_SUBJECT: 'Select subject',
  ADD_PROFILE_PHOTO: 'Add your profile photo',
  I_HAVE_READ: 'I have read and agree to the',
  AND: 'and',
  FAQ: 'FAQ',
  FOR_PROVIDERS_AND_PHARMACISTS: 'Policy Info',
  YOU_HAVE_UNSAVED: 'You have unsaved changes',
  USER_PROFILE_PHOTO: 'User profile photo',
  ACCOUNT_DELETED_PAGE_PHOTO: 'Account deleted page photo',
  DONT_HAVE_ACCOUNT: "Don't have an account?",
  REGISTER_NOW: 'Register now',
  HEALTHLINK_LOGO: 'HealthLink',
  TEXT_NOTIFICATION_PREFERENCE_ERR:
    'An error occurred while updating your text notification preferences. Please try again later.',
  EMAIL_NOTIFICATION_PREFERENCE_ERR:
    'An error occurred while updating your email notification preferences. Please try again later.',
  N0_PHONE_NUMBER_ON_FILE: 'You don’t have a phone number on file',
  NO_PHONE_NUMBER_ON_FILE_DESCRIPTION:
    'Please add a phone number to your personal information before opting in.',
  COMMUNICATION_PREFERENCES_LEGAL_COPY:
    'Text Messaging and Email are not a secure method of communicating and carry some risk of being read by a third party. Text Messages and Email may be recurring and include personal health information. For text messaging, messaging and data rates apply. I may revoke or withdraw this consent at any time. Withdrawal of consent for text messages can be made by replying STOP to the messages. {link}.',
  EMAIL_LEGAL_DISCLAIMER:
    'Email is not a secure method of communicating and carries some risk of being read by a third party. Email may be recurring.',
  VISIT_OUR_TERMS: 'Visit our terms',
  AGREE_AND_CONTINUE: 'Agree and Continue',
  GINA_CONSENT_TITLE: 'One last step...',
  GINA_CONSENT_VIEW: 'View Your Health Information Authorization',
  GINA_CONSENT_SUB_TITLE: 'Health Information Authorization',
  GINA_CONSENT_ERROR:
    'An error occurred while updating your GINA consent. Please try again later.',
  DOCUMENT_TITLE: '{title} - Optum Guide',
  ACCOUNT_ERROR: 'Account error',
  DEACTIVATED_ACCOUNT_ERROR_TITLE:
    'Your Optum Guide account has been deactivated.',
  ACCOUNT_ERROR_TEXT:
    'You can call or email our Care Guide team to assist you.',
  SEND_AN_EMAIL: 'Send an email',
  CALL: 'Call',
  WALLET: 'Benefits',
  WALLET_LANDING_TITLE: 'Your benefits and wellness info, all in one place',
  PROFILE_IMAGE_UPLOAD_ERROR:
    'Uh oh, there was an issue uploading your profile photo.',
  DATE_SIGNED: 'Date Signed',
  ACTIONS: 'Actions',
  VIEW_HEALTH_INFORMATION_AUTHORIZATION:
    'View Health Information Authorization',
  OPEN_MENU: 'Open Menu',
  MENU: 'Menu',
  ACCOUNT_SETTINGS: 'Account Settings',
  SUPPORT: 'Support',
  ABOUT: 'About',
  MAIN_NAVIGATION_LIST_LABEL: 'Main navigation links',
  WALLET_WITH_CLAIMS_LANDING_TITLE:
    'Your benefits, claims, and wellness info, all in one place.',
  NOT_ELIGIBLE_TO_ACCESS: 'You are not eligible to access Optum Guide.',
  REACH_OUT_TO_MEMBER_SERVICES_TEAM:
    'Please reach out to our member services team for any questions.',
  CALLBACK_REQUEST_PAGE_TITLE: 'Callback Request',
  CALLBACK_ERROR_HEADING: 'Something went wrong',
  CALLBACK_ERROR_BODY:
    "We're not sure what happened. Let's go back and try this one more time.",
  CALLBACK_STATUS_ERROR_BODY:
    "We couldn't load that page. Please try again later.",
  GO_BACK: 'Go back',
  CALLBACK_QUEUED_HEADING: 'Support is on the way!',
  CALLBACK_QUEUED_BODY_1: "You'll get a call shortly.",
  CALLBACK_QUEUED_SUCCESS_BODY_2:
    "Please keep your phone near you. If you miss our call, you'll need to submit another request.",
  GOT_IT: 'Got it!',
  CALLBACK_SUCCESS_HEADING: 'We got your request!',
  CALLBACK_SUCCESS_BODY_1: "Support is on the way. You'll get a call shortly.",
  CALLBACK_REQUEST_HEADING: 'Request a call',
  CALLBACK_REQUEST_BODY_1:
    "Share your contact info and you'll get a call shortly from us.",
  CALLBACK_REQUEST_BODY_2: 'What’s the best number to reach you? ',
  CALLBACK_REQUEST_LABEL: 'Phone number',
  PHONE_NUMBER_VALIDATION_ERROR_MESSAGE:
    'Please enter a valid 10-digit phone number.',
  CALLBACK_REQUEST_DISCLAIMER_TITLE: 'Disclaimer',
  CALLBACK_REQUEST_DISCLAIMER_BODY:
    'Please call 911 if you need immediate assistance.',
  CALLBACK_REQUEST_SUBMIT_BUTTON: 'Send request',
  ACCOUNT_SETUP: 'Account Setup',
  TERMS_REACCEPTANCE_HEADING:
    "We've updated our Privacy Policy and Terms of Service",
  TERMS_REACCEPTANCE_ERROR:
    'Please agree to the Privacy Policy and Terms of Service.',
  PHONE_NUMBER_OPTIONAL: 'Phone Number (optional)',
  EXPIRED_HEALTH_INFO_AUTH: 'Your health information authorization has expired',
  GINA_REACCEPT_SIGN: 'Complete to continue using the portal.',
  GINA_REACCEPT_CONFIRMATION:
    'Your GINA agreement has been signed. You can view this at any time in your <strong>Settings Menu.</strong>',
  MAINTENANCE_PAGE_HEADING_WITH_DATE:
    'Scheduled maintenance in progress until {endDate}',
  MAINTENANCE_PAGE_HEADING_NO_DATE: 'Scheduled maintenance in progress',
  MAINTENANCE_PAGE_BODY:
    'Thanks for your patience as we make some improvements. If you need assistance, call the number on your member ID card.',
  APP_NOTIFICATIONS: 'Optum Guide notifications',
  ALLOW_EMAIL_NOTIFICATIONS: 'Allow email notifications',
  RECEIVE_OCCASIONAL_EMAILS:
    'I’d like to receive occasional emails with alerts, reminders and tips to get the most out of the Optum Guide digital experience.',
  SIGN_ROI_SUCCESS_MSG_TITLE:
    'Your Digital Access Authorization has been successfully signed. You can change these permissions at any time in',
  SIGN_ROI: 'Sign Digital Access Authorization',
  UPDATE_ROI: 'Update Digital Access Authorization',
  EXPIRED_ROI_BANNER_WITH_DATE:
    'Your Digital Access Authorization expired on {expiryDate}.',
  EXPIRED_ROI_BANNER: 'Your Digital Access Authorization expired.',
  ROI_PAGE_HEADING: 'Digital Access Authorization',
  VIEW_ROI: 'View Digital Access Authorization',
  EDIT_ROI_MEMBERS: 'Edit members on Digital Access Authorization',
  FILE_UPLOAD: 'file upload',
  FILE_TOO_LARGE_ERROR:
    'The file you selected is too large. Please select a file under 1 MB.',
  OTHER_PREFERENCES_HEADING: 'Other preferences',
  OTHER_PREFERENCES_LINK: 'Manage additional communication preferences',
  OTHER_PREFERENCES_LINK_DESCRIPTION:
    'We want to provide your information quickly in ways that work best for you. Tell us how you would like to receive your communications.',
  USER_NOTIFIED_EMAIL_SUBTITLE: 'You’re getting notified on',
  EDIT_EMAIL: 'Edit email',
  PROFILE_PHOTO_UPDATED: 'Profile photo updated',
  PROFILE_PHOTO_UPDATE_FAILED: 'Profile photo update failed',
  ADD_TO_JOURNEY: 'Get started',
  EDIT_DISPLAY_NAME: 'Edit display name',
  DISPLAY_NAME_LABEL: 'Set your display name as',
  DISPLAY_NAME_HINT:
    'This name will only be used to communicate with you on your emails, notifications, and greetings in the app. All other communication will use your legal name.',
  DISPLAY_NAME_UPDATED: 'Display name updated',
  DISPLAY_NAME_UPDATE_FAILED: 'Display name update failed',
  ONBOARDING_EMAIL_HINT: 'Emails will be sent to: {email}',
  EDIT_CONTACT_INFORMATION: 'Edit contact information',
  LINK_OUT_MESSAGE: 'Get Started',
  STANDALONE_ACTIVITIES_DESCRIPTION: 'No Programs. Just one-off activities.',
  JOURNEY_DELIGHT_1:
    'Kudos to taking positive steps toward your personal growth.',
  JOURNEY_DELIGHT_2: 'Great job focusing on your overall well-being.',
  JOURNEY_DELIGHT_3: 'Knowledge is the key to understanding. Keep going!',
  ASSIGNED: 'Recommended Actions',
  NO_CONFIGURABLE_ACTIVITIES_EMPTY_STATE_EYEBROW: 'Courses & Activities',
  NO_CONFIGURABLE_ACTIVITIES_EMPTY_STATE_HEADING: 'Start something new today',
  NO_CONFIGURABLE_ACTIVITIES_EMPTY_STATE_DESCRIPTION:
    'Find courses and resources to support your overall well-being.',
  REMOVE_FROM_JOURNEY: 'Dismiss from journey',
  REMOVE_ACTIVITY: 'Dismiss Activity?',
  REMOVE_ACTIVITY_BUTTON: 'Dismiss',
  ARE_YOU_SURE_REMOVE_ACTIVITY:
    'Are you sure you want to dismiss this activity from your journey? This action can’t be undone.',
  ACTIVITY_REMOVED: 'Activity dismissed',
  ACTIVITY_DISMISS_ERROR:
    'We were unable to dismiss the activity at this time. Please try again.',
  CARE_ADVISOR_CONNECT: 'Care Advisor Connect',
  OPTUM_GUIDE: 'Optum Guide',
};
