import { GDSInteractiveTokens } from '@leagueplatform/genesis-core';

// Use interactive colours for interactive components (other than buttons) like links, focus indicators, and selection controls.
const interactive: GDSInteractiveTokens = {
  background: {
    default: '#FFFFFF',
    disabled: '#F0F0F0',
    hovered: '#F8F8F8',
    pressed: '#F0F0F0',
  },
  action: {
    primary: '#002677',
    hovered: '#5972A7',
    pressed: '#26478B',
    subdued: '#66686B',
    disabled: '#D6D6D6',
  },
  border: {
    default: '#949494',
    disabled: '#D6D6D6',
    hovered: '#002677',
    critical: {
      default: '#BA1B3B',
    },
  },
  icon: {
    default: '#FFFFFF',
    disabled: '#949494',
  },
  focus: {
    inner: '#002677',
    outer: '#002677',
  },
};

export const interactiveTokens = {
  interactive,
};
