import React, { useEffect, createContext, useMemo } from 'react';
import { fetchAndActivate } from 'firebase/remote-config';
import { remoteConfig, REMOTE_CONFIG_DEFAULT_VALUES } from 'common/firebase';
import { useQuery } from 'react-query';
import { captureAppMessage } from '@leagueplatform/observability';

export type RemoteConfigContextType = {
  isLoading: boolean;
  isDefault: boolean;
};

export const RemoteConfigContext = createContext<RemoteConfigContextType>({
  isLoading: false,
  isDefault: false,
});

export const RemoteConfigProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  /* In production by default Firebase throttles fetch requests to once per 12 hours (per session)
   * We also want to use the same flag values throughout a session to ensure a consistent user experience
   * Setting query cache/stale time to infinite ensures only one call to the server per session
   * For development/debugging these values can be overridden below (staleTime & cacheTime) to a lower threshold.
   * Additionally `remoteConfig.settings.minimumFetchIntervalMillis` should be set - see common/firebase.ts
   */
  const { isLoading, isError, error } = useQuery(
    ['FIREBASE_REMOTE_CONFIG'],
    () => fetchAndActivate(remoteConfig),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  const remoteConfigContext = useMemo(
    () => ({
      isLoading,
      isDefault: Boolean(isLoading || isError),
    }),
    [isLoading, isError],
  );

  useEffect(() => {
    if (isError) {
      captureAppMessage(
        'Firebase Remote Config: Failed to fetch, using default instead',
        {
          severityLevel: 'warning',
          errorName:
            (error as Error)?.message || 'error fetching remote config',
          context: {
            Context: {
              errorInfo: error ?? 'no info available',
              REMOTE_CONFIG_DEFAULT_VALUES,
            },
          },
        },
      );
    }
  }, [isError, error]);

  return (
    <RemoteConfigContext.Provider value={remoteConfigContext}>
      {children}
    </RemoteConfigContext.Provider>
  );
};
